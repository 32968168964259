import * as React from 'react';
import { errorHandler } from '../../../error/handler';
import { getQualita, getQualitaList } from '../../../fetch/lotto/attributes/qualita';
import { IQualita } from '../../../interfaces/lotto/attributes/models';
import { normalizer } from '../../../lib/normalizer';
import { useAppDispatch } from '../../../store/hooks';
import { emptyAction, getAction, listAction } from '../../../store/lotto/attributes/qualita/actions';
import { IQualitaState } from '../../../store/lotto/attributes/qualita/types';
import { endActionToDoAction, startActionToDoAction } from '../../../store/ui/actions';

export const useGetQualita = (id: number) => {
	const dispatch = useAppDispatch();
	React.useEffect(() => {
		const effect = async () => {
			if (!id) return;
			const actionToDo = 'get-qualita';
			try {
				dispatch(startActionToDoAction({ actionToDo }));
				const qualita = await getQualita(id);
				dispatch(getAction(qualita));
				dispatch(endActionToDoAction({ actionToDo, success: true }));
			} catch (err) {
				errorHandler(err, actionToDo);
			}
		}
		effect();
	}, [dispatch, id])
}

export const useQualitaList = (query: number) => {
	const dispatch = useAppDispatch();
	React.useEffect(() => {
		const effect = async () => {
			if (!query) return;
			const actionToDo = 'list-qualita';
			try {
				dispatch(startActionToDoAction({ actionToDo }));
				const { results, items: _items } = await getQualitaList();
				_items.sort((itemA, itemB) => (!!itemA?.ordinamento && !!itemB.ordinamento) ? itemA.ordinamento - itemB.ordinamento : 0);
				const { list, items } = normalizer<number, IQualita>(_items, "id");
				const actionPayload: IQualitaState = {
					results,
					list,
					items
				}
				dispatch(listAction(actionPayload));
				dispatch(endActionToDoAction({ actionToDo, success: true }));
			} catch (err) {
				errorHandler(err, actionToDo);
			}
			return () => {
				dispatch(emptyAction());
			}
		}
		effect();
	}, [dispatch, query])
}
