import { Icon, Tooltip } from "@mui/material";
import { GridActionsCellItem, GridAlignment, GridColumnHeaderParams, GridColumns, GridRenderCellParams, GridRowParams, GridValueGetterParams } from "@mui/x-data-grid";
import { IDettaglio } from "../../../../../interfaces/dettaglio/models";
import { CellTipoLotto } from "../../../shared/tableCells";
import { ICreateColumnsPros, ICreateGridColumnsPros } from "./interfaces";

const createAllColumns = ({ onView, rowsSelected, setRowsSelected }: ICreateColumnsPros): GridColumns<IDettaglio> => [
	{
		field: 'actions',
		type: 'actions',
		disableExport: true,
		align: 'left' as GridAlignment,
		hideable: false,
		maxWidth: 50,
		getActions: (params: GridRowParams<IDettaglio>) => [
			<GridActionsCellItem
				icon={<Tooltip title="Apri in un altra scheda"><Icon className="fa-light fa-arrow-up-right-from-square" sx={{ overflow: "visible" }} /></Tooltip>}
				label="Open"
				onClick={() => onView(params.row.idLotto as number)}
			/>
		],
		renderHeader: (_params: GridColumnHeaderParams<any, IDettaglio>) => (
			<>
				{!!rowsSelected.length &&
					<GridActionsCellItem
						icon={<Tooltip title="Deseleziona tutti gli elementi"><Icon className="fa-light fa-square-check" sx={{ overflow: "visible" }} /></Tooltip>}
						label="Open"
						onClick={() => setRowsSelected([])}
					/>
				}
				{!rowsSelected.length &&
					<></>
				}
			</>
		),
	},
	{
		field: 'tipoLotto',
		headerName: 'Tipo',
		type: 'actions',
		renderCell: (params: GridRenderCellParams<string>) => <CellTipoLotto dettaglio={params.row} />
	},
	{
		field: 'codice',
		headerName: 'Codice',
		valueGetter: (params: GridValueGetterParams<undefined, IDettaglio>) => params?.row?.lotto?.codice,
		width: 120
	},
	{
		field: 'suffissoCodice',
		headerName: '',
		width: 40,
	},
	{
		field: 'cava',
		headerName: 'Cava',
		width: 120,
		valueGetter: (params: GridValueGetterParams<undefined, IDettaglio>) => params?.row?.lotto?.cava?.nome
	},
	{
		field: 'codiceCava',
		headerName: 'Codice Cava',
		valueGetter: (params: GridValueGetterParams<undefined, IDettaglio>) => params?.row?.lotto?.codiceCava
	},
	{
		field: 'dataCarico',
		headerName: 'Data Carico',
		type: 'date',
		valueGetter: (params: GridValueGetterParams<undefined, IDettaglio>) => params?.row?.lotto?.dataCarico
	},
	{
		field: 'materiale',
		headerName: 'Materiale',
		width: 120,
		valueGetter: (params: GridValueGetterParams<undefined, IDettaglio>) => params?.row?.lotto?.materiale?.nome
	},
	{
		field: 'qualita',
		headerName: 'Qualità',
		width: 120,
		valueGetter: (params: GridValueGetterParams<undefined, IDettaglio>) => params?.row?.lotto?.qualita?.nome
	},
	{
		field: 'categoria',
		headerName: 'Categoria',
		width: 120,
		valueGetter: (params: GridValueGetterParams<undefined, IDettaglio>) => params?.row?.lotto?.categoria?.nome
	},
	{
		field: 'fondo',
		headerName: 'Fondo',
		width: 120,
		valueGetter: (params: GridValueGetterParams<undefined, IDettaglio>) => params?.row?.lotto?.fondo?.nome
	},
	{
		field: 'venatura',
		headerName: 'Venatura',
		width: 120,
		valueGetter: (params: GridValueGetterParams<undefined, IDettaglio>) => params?.row?.lotto?.venatura?.nome
	},
	{
		field: 'uso',
		headerName: 'Uso',
		width: 60,
		valueGetter: (params: GridValueGetterParams<undefined, IDettaglio>) => params?.row?.lotto?.uso?.nome
	},
	{
		field: 'pezzi',
		headerName: 'Pezzi',
		type: 'number',
		renderCell: (params: GridRenderCellParams<string>) => <b>{params.formattedValue}</b>,
		width: 60
	},
	{
		field: 'quantitaUM',
		headerName: 'Quantità',
		type: 'number',
		renderCell: (params: GridRenderCellParams<number>) => {
			const value = parseFloat(String(params.value).replaceAll(',', '.'));
			return Number(value).toLocaleString("it-IT", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
		},
		width: 80
	},
	{
		field: 'um',
		headerName: '',
		sortable: false,
		valueGetter: (params: GridValueGetterParams<undefined, IDettaglio>) => (params?.value) ? String(params.value) : "",
		width: 20
	},
	{
		field: 'prezzo',
		headerName: 'Prezzo medio (€)',
		type: 'number',
		renderCell: (params: GridRenderCellParams<number>) => {
			const value = parseFloat(String(params.value).replaceAll(',', '.'));
			return Number(value) ? Number(value).toLocaleString("it-IT", { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : "-------"
		},
		width: 120
	},
	{
		field: 'um_prezzo',
		headerName: '',
		sortable: false,
		valueGetter: (params: GridValueGetterParams<undefined, IDettaglio>) => (params?.row?.um) ? String(params?.row?.um) : "",
		width: 20
	},
	{
		field: 'magazzino',
		headerName: 'magazzino',
		valueGetter: (params: GridValueGetterParams<undefined, IDettaglio>) => params?.row?.magazzino?.nome,
		width: 120
	},
	{
		field: 'zona',
		headerName: 'Zona',
		valueGetter: (params: GridValueGetterParams<undefined, IDettaglio>) => params?.row?.zona?.nome,
		width: 80
	},
	{
		field: 'fila',
		headerName: 'Fila',
		valueGetter: (params: GridValueGetterParams<undefined, IDettaglio>) => params?.row?.fila?.nome,
		width: 80
	},
	{
		field: "opzCliente",
		headerName: "Cliente",
		minWidth: 220,
	},
	{
		field: "opzNote",
		headerName: "Note",
		sortable: false,
		minWidth: 380,
	}
]

export const createDefaultGridColumns = ({ canViewQuarry, canViewCommercials, canViewStoragePlace, canViewCommercialOptions, rowsSelected, setRowsSelected, onView }: ICreateGridColumnsPros): GridColumns<IDettaglio> => {
	let columns = createAllColumns({ onView, rowsSelected, setRowsSelected });
	if (!canViewQuarry) columns = columns.filter((col => col.field !== 'cava' && col.field !== 'codiceCava'));
	if (!canViewCommercials) columns = columns.filter((col => col.field !== 'prezzo' && col.field !== 'um_prezzo'));
	if (!canViewStoragePlace) columns = columns.filter((col => col.field !== 'magazzino' && col.field !== 'zona' && col.field !== 'fila'));
	if (!canViewCommercialOptions) columns = columns.filter((col => col.field !== 'opzStato' && col.field !== 'opzCliente' && col.field !== 'agente' && col.field !== 'opzNote'));
	return columns;
}