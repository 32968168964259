import { List, ListSubheader } from "@mui/material";
import { useEffect, useState } from "react";
import { EOptionType } from "../../../../../../../interfaces/enums";
import { IFilterProps } from "../../interfaces";
import FileFilter from "./file";
import MagazziniFilter from "./magazzini";
import ZoneFilter from "./zone";

function PosizioniFiltersContainer({ filters, setFilters }: IFilterProps) {
	const [showZone, setShowZone] = useState<boolean>(false);
	const [showFile, setShowFile] = useState<boolean>(false);
	useEffect(() => {
		const foundMagazzinoFilter = !!filters.find(f => f.type === EOptionType.magazzino);
		if (foundMagazzinoFilter) setShowZone(true);
		else setShowZone(false);
		const foundZoneFilter = !!filters.find(f => f.type === EOptionType.zona);
		if (foundZoneFilter) setShowFile(true);
		else setShowFile(false);
	}, [filters])
	return (
		<List
			dense={true}
			sx={{ p: 0 }}
			component="nav"
			subheader={
				<ListSubheader component="div">
					Posizioni
				</ListSubheader>
			}
		>
			<MagazziniFilter
				filters={filters}
				setFilters={setFilters}
			/>
			{showZone &&
				<ZoneFilter
					filters={filters}
					setFilters={setFilters}
				/>
			}
			{showFile &&
				<FileFilter
					filters={filters}
					setFilters={setFilters}
				/>
			}
		</List>
	)
}

export default PosizioniFiltersContainer;