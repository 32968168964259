import { Accordion, AccordionDetails, AccordionSummary, Box, Card, CardActionArea, CardMedia, Icon, IconButton, Tooltip, Typography } from "@mui/material";
import { MouseEvent, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ILotto } from "../../../../../interfaces/lotto/models";
import { IUser } from "../../../../../interfaces/user/models";
import { dettagliFilterAvailableSlabs, dettagliFilterExistingBlocks, dettagliFilterExistingSlabs } from "../../../../../lib/functions";
import { getJWT } from "../../../../../lib/jwt";
import { useAppSelector } from "../../../../../store/hooks";
import UpdateDialog from "./dialogs/updateDialog";

const IMAGE_URL = `${process.env.REACT_APP_API_URL}image/`;

const i18_PATH = 'prodotto.view.lottoMain.'

interface ILottoMainProps {
	lotto: ILotto;
	user: IUser | null;
	view: () => void;
}

function LottoMain({ lotto, user, view }: ILottoMainProps) {
	const { t } = useTranslation();

	const imageContainer = useRef<HTMLElement>();
	const [updateDialogOpen, setUpdateDialogOpen] = useState<boolean>(false);
	const [canUpdateInfo, setCanUpdateInfo] = useState<boolean>(false);
	const [canViewQuarry, setCanViewQuarry] = useState<boolean>(false);
	const [isNotAnonymousUser, setIsNotAnonymousUser] = useState<boolean>(false);
	const [imageWidth, setImageWidth] = useState<number>(0);
	const [cover, setCover] = useState<string | null>(null);
	const [initQuantity, setInitQuantity] = useState<string>();
	const [initNumber, setInitNumber] = useState<number>();
	const [availableQuantity, setAvailableQuantity] = useState<string>();
	const [availableNumber, setAvailableNumber] = useState<number>();
	const [initWeight, setInitWeight] = useState<string>();
	const [isDaSegare, setIsDaSegare] = useState<boolean>(false);

	const lastActionDone = useAppSelector((state) => state.ui.lastActionDone);

	const onUpdate = (event: MouseEvent<HTMLButtonElement>) => {
		event.stopPropagation();
		setUpdateDialogOpen(true);
	}
	const getSize = () => {
		const width = imageContainer?.current?.clientWidth;
		setImageWidth(width || 0);
	}
	useEffect(() => {
		window.addEventListener("resize", getSize);
		getSize();
		return () => window.removeEventListener("resize", getSize);
	}, []);
	useEffect(() => {
		if (lotto.images?.length) {
			const jwt = getJWT();
			const coverItem = lotto.images.find(image => image.isCover) ?? lotto.images?.[0];
			console.log({ coverItem });
			const _cover = coverItem ? `${IMAGE_URL}${coverItem.mdPath}?token=${jwt})` : null;
			setCover(_cover);
		}
		const initialSlabs = dettagliFilterExistingSlabs(lotto.dettagli || []);
		const availableSlabs = dettagliFilterAvailableSlabs(lotto.dettagli || []);
		const availableBlocks = dettagliFilterExistingBlocks(lotto.dettagli || []);
		const _initWeight = availableBlocks.reduce((previousValue, currentValue) => previousValue + currentValue.quantitaUM, 0);
		const _initNumber = initialSlabs.reduce((previousValue, currentValue) => previousValue + currentValue.pezzi, 0);
		const _initQuantity = initialSlabs.reduce((previousValue, currentValue) => previousValue + currentValue.quantitaUM, 0);
		const _availableNumber = availableSlabs.reduce((previousValue, currentValue) => previousValue + currentValue.pezzi, 0);
		const _availableQuantity = availableSlabs.reduce((previousValue, currentValue) => previousValue + currentValue.quantitaUM, 0);
		const _isDaSegare = lotto.daSegare;

		const initQuantityFormatted = Number(_initQuantity).toLocaleString("it-IT", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
		const availableQuantityFormatted = Number(_availableQuantity).toLocaleString("it-IT", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
		const initWeightFormatted = Number(_initWeight).toLocaleString("it-IT", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
		setInitQuantity(initQuantityFormatted);
		setInitNumber(_initNumber);
		setAvailableQuantity(availableQuantityFormatted);
		setAvailableNumber(_availableNumber);
		setInitWeight(initWeightFormatted);
		setIsDaSegare(_isDaSegare);
	}, [lotto]);
	useEffect(() => {
		const _canViewQuery = !!user?.admin || !!user?.user_group?.group_permissions?.canViewQuarry;
		const _canUpdateInfo = !!user?.admin || !!user?.user_group?.group_permissions?.canSetFeatures;
		const _isNotAnonymousUser = !!user && !user.unknown;
		setCanUpdateInfo(_canUpdateInfo);
		setCanViewQuarry(_canViewQuery);
		setIsNotAnonymousUser(_isNotAnonymousUser);
	}, [user])
	useEffect(() => {
		if (!lastActionDone) return;
		if (['update-lotto'].includes(lastActionDone)) {
			setUpdateDialogOpen(false);
		}
	}, [lastActionDone]);
	return (
		<>
			<Accordion defaultExpanded={true}>
				<AccordionSummary
					expandIcon={<Icon className="fa-light fa-angle-down" fontSize="small" sx={{ overflow: "visible" }} />}
					aria-controls="qualita-content"
					id="qualita-header"
				>
					<Typography variant="subtitle1" sx={{ flex: 1 }} >{t(`${i18_PATH}Informazioni`)}</Typography>
					<Box sx={{ mr: 2 }}>
						{canUpdateInfo &&
							<Tooltip title={t(`${i18_PATH}Modifica informazioni`)}>
								<IconButton onClick={onUpdate}>
									<Icon className="fa-light fa-pen-circle" sx={{ overflow: "visible" }} />
								</IconButton>
							</Tooltip>
						}
					</Box>
				</AccordionSummary>
				<AccordionDetails>
					<Box sx={{ display: "flex", gap: 1 }}>
						<Box sx={{ display: "flex", flexDirection: "column", gap: 1, flex: 1 }}>
							<Box sx={{ display: "flex", flex: 1, border: "1px solid #e3e3e3", borderRadius: 4 }}>
								<Box sx={{ display: "flex", flex: 1, flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
									<Box sx={{ display: "flex", flex: 1, alignItems: "center" }}>
										<Box sx={{ p: 1, display: "flex", alignItems: "baseline" }}>
											<Typography variant="overline" color="text.secondary" sx={{ mr: 1 }}>{t(`${i18_PATH}Codice`)}:</Typography>
											<Typography variant="h4" component="span" color="text.primary">{lotto?.codice}</Typography>
										</Box>
									</Box>
									{canViewQuarry &&
										<Box sx={{ display: "flex", flex: 1, alignItems: "center" }}>
											<Box sx={{ p: 1, display: "flex", flex: 1, alignItems: "baseline" }}>
												<Typography variant="overline" color="text.secondary" sx={{ mr: 1 }}>{t(`${i18_PATH}Cava`)}:</Typography>
												<Typography variant="body1" component="span" color="text.primary">{lotto.cava?.nome}</Typography>
												{!!lotto.codiceCava && <Typography variant="body1" component="span" color="text.primary" sx={{ ml: 1 }}> - {lotto.codiceCava}</Typography>}
											</Box>
										</Box>
									}
								</Box>
							</Box>
							{isNotAnonymousUser &&
								<Box sx={{ display: "flex", flex: 1, border: "1px solid #e3e3e3", borderRadius: 4 }}>
									<Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
										<Box sx={{ display: "flex", flex: 1, alignItems: "center" }}>
											<Box sx={{ p: 1, display: "flex", alignItems: "baseline" }}>
												<Typography variant="overline" color="text.secondary" sx={{ mr: 1 }}>{t(`${i18_PATH}Materiale`)}:</Typography>
												<Typography variant="body1" component="span" color="text.primary">{lotto.materiale?.nome}</Typography>
											</Box>
										</Box>
										<Box sx={{ display: "flex", flex: 1, alignItems: "center" }}>
											<Box sx={{ p: 1, display: "flex", alignItems: "baseline" }}>
												<Typography variant="overline" color="text.secondary" sx={{ mr: 1 }}>{t(`${i18_PATH}Categoria`)}:</Typography>
												<Typography variant="body1" component="span" color="text.primary">{lotto.categoria?.nome}</Typography>
											</Box>
										</Box>
										<Box sx={{ display: "flex", flex: 1, alignItems: "center" }}>
											<Box sx={{ p: 1, display: "flex", alignItems: "baseline" }}>
												<Typography variant="overline" color="text.secondary" sx={{ mr: 1 }}>{t(`${i18_PATH}Venatura`)}:</Typography>
												<Typography variant="body1" component="span" color="text.primary">{lotto.venatura?.nome}</Typography>
											</Box>
										</Box>
									</Box>
									<Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
										<Box sx={{ display: "flex", flex: 1, alignItems: "center" }}>
											<Box sx={{ p: 1, flex: 1, display: "flex", alignItems: "baseline" }}>
												<Typography variant="overline" color="text.secondary" sx={{ mr: 1 }}>{t(`${i18_PATH}Qualità`)}:</Typography>
												<Typography variant="body1" component="span" color="text.primary">{lotto.qualita?.nome}</Typography>
											</Box>
										</Box>
										<Box sx={{ display: "flex", flex: 1, alignItems: "center" }}>
											<Box sx={{ p: 1, flex: 1, display: "flex", alignItems: "baseline" }}>
												<Typography variant="overline" color="text.secondary" sx={{ mr: 1 }}>{t(`${i18_PATH}Fondo`)}:</Typography>
												<Typography variant="body1" component="span" color="text.primary">{lotto.fondo?.nome}</Typography>
											</Box>
										</Box>
										<Box sx={{ display: "flex", flex: 1, alignItems: "center" }}>
											<Box sx={{ p: 1, flex: 1, display: "flex", alignItems: "baseline" }}>
												<Typography variant="overline" color="text.secondary" sx={{ mr: 1 }}>{t(`${i18_PATH}Uso`)}:</Typography>
												<Typography variant="body1" component="span" color="text.primary">{lotto.uso?.nome}</Typography>
											</Box>
										</Box>
									</Box>
								</Box>
							}
							{!isNotAnonymousUser &&
								<Box sx={{ display: "flex", flex: 1, border: "1px solid #e3e3e3", borderRadius: 4 }}>
									<Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
										<Box sx={{ display: "flex", flex: 1, alignItems: "center" }}>
											<Box sx={{ p: 1, flex: 1, display: "flex", alignItems: "baseline", justifyContent: "center" }}>
												<Typography variant="overline" color="text.secondary" sx={{ mr: 1 }}>{t(`${i18_PATH}Qualità`)}:</Typography>
												<Typography variant="body1" component="span" color="text.primary">{lotto.qualita?.nome}</Typography>
											</Box>
										</Box>
										<Box sx={{ display: "flex", flex: 1, alignItems: "center" }}>
											<Box sx={{ p: 1, flex: 1, display: "flex", alignItems: "baseline", justifyContent: "center" }}>
												<Typography variant="overline" color="text.secondary" sx={{ mr: 1 }}>{t(`${i18_PATH}Fondo`)}:</Typography>
												<Typography variant="body1" component="span" color="text.primary">{lotto.fondo?.nome}</Typography>
											</Box>
										</Box>
										<Box sx={{ display: "flex", flex: 1, alignItems: "center", justifyContent: "center" }}>
											<Box sx={{ p: 1, display: "flex", alignItems: "baseline" }}>
												<Typography variant="overline" color="text.secondary" sx={{ mr: 1 }}>{t(`${i18_PATH}Venatura`)}:</Typography>
												<Typography variant="body1" component="span" color="text.primary">{lotto.venatura?.nome}</Typography>
											</Box>
										</Box>
									</Box>
								</Box>
							}
							{(isDaSegare && isNotAnonymousUser) &&
								<Box sx={{ display: "flex", flex: 1, border: "1px solid #e3e3e3", borderRadius: 4 }}>
									<Box sx={{ display: "flex", flex: 1, flexDirection: "column", alignItems: "center" }}>
										<Box sx={{ display: "flex", flex: 1, alignItems: "center" }}>
											<Box sx={{ p: 1, display: "flex", alignItems: "baseline" }}>
												<Typography variant="overline" color="text.primary" sx={{ fontSize: "1rem", mr: 1 }}>{t(`${i18_PATH}Blocco da segare`)}:</Typography>
												<Typography variant="body1" component="span" color="text.secondary">{initWeight} TN</Typography>
											</Box>
										</Box>
									</Box>
								</Box>
							}
							{(!isDaSegare && isNotAnonymousUser) &&
								<Box sx={{ display: "flex", flex: 1, border: "1px solid #e3e3e3", borderRadius: 4 }}>
									<Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
										<Box sx={{ display: "flex", flex: 1, alignItems: "center" }}>
											<Box sx={{ p: 1, display: "flex", alignItems: "baseline" }}>
												<Typography variant="overline" color="text.secondary" sx={{ mr: 1 }}>{t(`${i18_PATH}Quantità iniziale`)}:</Typography>
												<Typography variant="body1" component="span" color="text.primary">{initQuantity} {t('MQ')}</Typography>
											</Box>
										</Box>
										<Box sx={{ display: "flex", flex: 1, alignItems: "center" }}>
											<Box sx={{ p: 1, display: "flex", alignItems: "baseline" }}>
												<Typography variant="overline" color="text.secondary" sx={{ mr: 1 }}>{t(`${i18_PATH}Quantità disponibile`)}:</Typography>
												<Typography variant="body1" component="span" color="text.primary">{availableQuantity} {t('MQ')}</Typography>
											</Box>
										</Box>
									</Box>
									<Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
										<Box sx={{ display: "flex", flex: 1, alignItems: "center" }}>
											<Box sx={{ p: 1, display: "flex", alignItems: "baseline" }}>
												<Typography variant="overline" color="text.secondary" sx={{ mr: 1 }}>{t(`${i18_PATH}Pezzi iniziali`)}:</Typography>
												<Typography variant="body1" component="span" color="text.primary">{initNumber}</Typography>
											</Box>
										</Box>
										<Box sx={{ display: "flex", flex: 1, alignItems: "center" }}>
											<Box sx={{ p: 1, display: "flex", alignItems: "baseline" }}>
												<Typography variant="overline" color="text.secondary" sx={{ mr: 1 }}>{t(`${i18_PATH}Pezzi disponibili`)}:</Typography>
												<Typography variant="body1" component="span" color="text.primary">{availableNumber}</Typography>
											</Box>
										</Box>
									</Box>
								</Box>
							}
						</Box>
						<Box
							sx={{
								display: "flex",
								flex: 1,
								border: "1px solid #e3e3e3",
								borderRadius: 4
							}}
							ref={imageContainer}>
							<Card sx={{ flex: 1 }}>
								<CardActionArea onClick={view}>
									{!!cover &&
										<CardMedia
											component="img"
											height={imageWidth * 0.5625}
											image={cover}
											sx={{ objectFit: "contain" }}
											alt="cover"
										/>
									}
									{!cover &&
										<Box sx={{
											display: 'flex',
											justifyContent: "center",
											alignItems: "center",
											height: imageWidth * 0.5625
										}}>
											<Tooltip title={t(`${i18_PATH}Nessuna Immagine`)}>
												<Icon className="fa-light fa-image-slash" fontSize="large" color="action" sx={{ overflow: "visible" }} />
											</Tooltip>
										</Box>
									}
								</CardActionArea>
							</Card>
						</Box>
					</Box>
				</AccordionDetails>
			</Accordion>
			<UpdateDialog
				lotto={lotto}
				isOpen={updateDialogOpen}
				close={() => setUpdateDialogOpen(false)}
			/>
		</>
	)
}

export default LottoMain;