import { List, ListSubheader } from "@mui/material";
import { IFilterProps } from "../../interfaces";
import CategorieFilter from "./categorie";
import FondiFilter from "./fondi";
import QualitaFilter from "./qualita";
import UsiFilter from "./uso";
import VenatureFilter from "./venature";

function QualitaFiltersContainer({ filters, setFilters }: IFilterProps) {
	return (
		<List
			dense={true}
			sx={{ p: 0 }}
			component="nav"
			subheader={
				<ListSubheader component="div">
					Qualità
				</ListSubheader>
			}
		>
			<QualitaFilter
				filters={filters}
				setFilters={setFilters}
			/>
			<CategorieFilter
				filters={filters}
				setFilters={setFilters}
			/>
			<FondiFilter
				filters={filters}
				setFilters={setFilters}
			/>
			<VenatureFilter
				filters={filters}
				setFilters={setFilters}
			/>
			<UsiFilter
				filters={filters}
				setFilters={setFilters}
			/>
		</List>
	)
}

export default QualitaFiltersContainer;