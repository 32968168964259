export enum EOptionType {
	"statoLavorazione" = "statoLavorazione",
	"categoria" = "categoria",
	"cava" = "cava",
	"codice" = "codice",
	"fondo" = "fondo",
	"magazzino" = "magazzino",
	"zona" = "zona",
	"fila" = "fila",
	"materiale" = "materiale",
	"qualita" = "qualita",
	"tipoLotto" = "tipoLotto",
	"uso" = "uso",
	"venatura" = "venatura",
	"prezzo" = "prezzo",
	"disponibilita" = "disponibilita",
	"cliente" = "cliente",
	"daSegare" = "daSegare"
}