import { Box, Chip, Icon, IconButton, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ICellTreeNodeParams, ICellVendutoParams } from "./interfaces";

const i18_PATH = 'prodotto.view.lottoDettagli.tableCells.'

export function CellTreeNode({ id, leaf, depth, tipo, onOpen, onClose, isOpen }: ICellTreeNodeParams) {
	const paddingLeft = 2 * (depth || 0);
	return (
		<Box sx={{ display: "flex", alignItems: "center", pl: paddingLeft }}>
			{(!leaf && isOpen) &&
				<IconButton onClick={() => onClose(id)}>
					<Icon className="fa-light fa-angle-down" fontSize="small" sx={{ overflow: "visible" }} />
				</IconButton>
			}
			{(!leaf && !isOpen) &&
				<IconButton onClick={() => onOpen(id)}>
					<Icon className="fa-light fa-angle-right" fontSize="small" sx={{ overflow: "visible" }} />
				</IconButton>
			}
			{leaf &&
				<Box sx={{
					width: "36px",
					height: "36px"
				}} />
			}
			{tipo === "Blocco" &&
				<Tooltip title="Blocco">
					<Icon className="fa-light fa-cube" sx={{ overflow: "visible" }} />
				</Tooltip>
			}
			{tipo === "Lastre" &&
				<Tooltip title="Lastre">
					<Icon className="fa-light fa-layer-group" sx={{ overflow: "visible" }} />
				</Tooltip>
			}
		</Box>
	)
}

export function CellVenduto({ value }: ICellVendutoParams) {
	const { t } = useTranslation();
	return (
		<Box sx={{ flex: 1, display: "flex", alignItems: "center", justifyContent: "center" }}>
			{value === 0 &&
				<Tooltip title={t(`${i18_PATH}Non venduto`)}>
					<Icon className="fa-light fa-square-dashed" sx={{ overflow: "visible" }} />
				</Tooltip>
			}
			{value > 0 &&
				<Tooltip title={t(`${i18_PATH}Venduto`)}>
					<Icon className="fa-light fa-square-check" sx={{ overflow: "visible" }} />
				</Tooltip>
			}
		</Box>
	)
}

export function CellAttributi({ value }: any) {
	if (!value || value.length === 0) return null;
	const attributesArray = [value]
	const attributesString = attributesArray?.join(' - ');
	return (
		<Box sx={{ flex: 1, display: "flex", alignItems: "center", justifyContent: "center" }}>
			<Chip sx={{ pr: 1, mr: 1 }} icon={<Icon className="fa-light fa-screwdriver-wrench" sx={{ overflow: "visible" }} />} label={attributesString} />
		</Box >
	)
}

export function Descendant() {
	return (
		<Box sx={{ flex: 1, display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
			<Icon className="fa-light fa-l" sx={{ overflow: "visible" }} />
		</Box>
	)
}
