import { GridColumnVisibilityModel } from "@mui/x-data-grid";

export const defaultColumnVisibilityModel: GridColumnVisibilityModel = {
	cava: false,
	codiceCava: false,
	dataCarico: false,
	materiale: false,
	uso: false,
	magazzino: false,
	zona: false,
	fila: false,
}