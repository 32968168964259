import { GridColumnVisibilityModel } from "@mui/x-data-grid";
import { IDettaglio } from "../interfaces/dettaglio/models";
import { EShowColumnState } from "../routes/prodotto/view/components/lottoDettagli/interfaces";

export const storeColumnVisibilityModel = (modelName: string, model: GridColumnVisibilityModel | null): void => {
	if (!modelName) return;
	if (!!model) localStorage.setItem(modelName, JSON.stringify(model));
}
export const getColumnVisibilityModel = (modelName: string): GridColumnVisibilityModel | null => {
	if (!modelName) return null;
	const model = localStorage.getItem(modelName);
	return (!!model) ? JSON.parse(model) as GridColumnVisibilityModel : null;
}

export const getDetailsTableViewMode = (): EShowColumnState | null => {
	return localStorage.getItem('detailsTableViewMode') as EShowColumnState ?? null;
}

export const storeDetailsTableViewMode = (mode: EShowColumnState): void => {
	localStorage.setItem('detailsTableViewMode', mode as string);
}

export const dettaglioHasPrice = (item: IDettaglio): boolean => parseFloat(String(item.prezzo).replaceAll(',', '.')) > 0

export const dettaglioIsBlock = (item: IDettaglio): boolean => item.idTipoLotto === 1;

export const dettaglioIsSlab = (item: IDettaglio): boolean => item.idTipoLotto === 2;

export const dettaglioDoesExist = (item: IDettaglio): boolean => item.numDettaglioFigli === 0;

export const dettaglioIsNotSold = (item: IDettaglio): boolean => dettaglioDoesExist(item) && item.venduto === 0

export const dettaglioIsAvailable = (item: IDettaglio): boolean => dettaglioIsNotSold(item) && (item.idDisponibilita === 5 || item.idDisponibilita === 1);

export const isAllSlabs = (rows: IDettaglio[]): boolean => !!rows.length && !rows.find(row => row.idTipoLotto === 1);

export const dettagliFilterBlocks = (items: IDettaglio[]): IDettaglio[] => items.filter(dettaglioIsBlock);

export const dettagliFilterSlabs = (items: IDettaglio[]): IDettaglio[] => items.filter(dettaglioIsSlab);

export const dettagliFilterExisting = (items: IDettaglio[]): IDettaglio[] => items.filter(dettaglioDoesExist);

export const dettagliFilterExistingSlabs = (items: IDettaglio[]): IDettaglio[] => items.filter(item =>
	dettaglioIsSlab(item) &&
	dettaglioDoesExist(item)
);

export const dettagliFilterExistingBlocks = (items: IDettaglio[]): IDettaglio[] => items.filter(item =>
	dettaglioIsBlock(item) &&
	dettaglioDoesExist(item)
);

export const dettagliFilterNotSold = (items: IDettaglio[]): IDettaglio[] => items.filter(dettaglioIsNotSold);

export const dettagliFilterNotSoldSlabs = (items: IDettaglio[]): IDettaglio[] => items.filter(item =>
	dettaglioIsSlab(item) &&
	dettaglioIsNotSold(item)
);

export const dettagliFilterNotSoldBlocks = (items: IDettaglio[]): IDettaglio[] => items.filter(item =>
	dettaglioIsBlock(item) &&
	dettaglioIsNotSold(item)
);


export const dettagliFilterAvailable = (items: IDettaglio[]): IDettaglio[] => items.filter(dettaglioIsAvailable);

export const dettagliFilterAvailableSlabs = (items: IDettaglio[]): IDettaglio[] => items.filter(item =>
	dettaglioIsSlab(item) &&
	dettaglioIsAvailable(item)
);

export const dettagliFilterAvailableBlocks = (items: IDettaglio[]): IDettaglio[] => items.filter(item =>
	dettaglioIsBlock(item) &&
	dettaglioIsAvailable(item)
);

export const getElementsNumber = (rows: unknown[]): number => rows.length;

export const getTotalNumberOfSlabs = (rows: IDettaglio[]): number => rows.filter(row => row.tipo_lotto.nome === "Lastre")
	.reduce((previousValue, currentValue) => previousValue + currentValue.pezzi, 0);

export const getTotalNumberOfBlocks = (rows: IDettaglio[]): number => rows.filter(row => row.tipo_lotto.nome === "Blocco")
	.reduce((previousValue, currentValue) => previousValue + currentValue.pezzi, 0);

export const getTotalMQ = (rows: IDettaglio[]): string => rows.filter(row => row.tipo_lotto.nome === "Lastre")
	.reduce((previousValue, currentValue) => previousValue + currentValue.quantitaUM, 0)
	.toLocaleString("it-IT", { minimumFractionDigits: 2, maximumFractionDigits: 2 });

export const getTotalTN = (rows: IDettaglio[]): string => rows.filter(row => row.tipo_lotto.nome === "Blocco")
	.reduce((previousValue, currentValue) => previousValue + currentValue.quantitaUM, 0)
	.toLocaleString("it-IT", { minimumFractionDigits: 2, maximumFractionDigits: 2 });

export const getTotalSlabsPrice = (rows: IDettaglio[]): string => rows.filter(row => row.tipo_lotto.nome === "Lastre")
	.reduce((previousValue, currentValue) => {
		const priceMQ = parseFloat(String(currentValue.prezzo).replaceAll(',', '.'));
		return previousValue + (priceMQ * currentValue.quantitaUM);
	}, 0)
	.toLocaleString("it-IT", { minimumFractionDigits: 2, maximumFractionDigits: 2 });

export const getTotalBlocksPrice = (rows: IDettaglio[]): string => rows.filter(row => row.tipo_lotto.nome === "Blocco")
	.reduce((previousValue, currentValue) => {
		const priceTN = parseFloat(String(currentValue.prezzo).replaceAll(',', '.'));
		return previousValue + (priceTN * currentValue.quantitaUM);
	}, 0)
	.toLocaleString("it-IT", { minimumFractionDigits: 2, maximumFractionDigits: 2 });

export const getMQPrice = (rows: IDettaglio[]): string => {
	const slabs = rows.filter(row => row.tipo_lotto.nome === "Lastre" && parseFloat(String(row.prezzo).replaceAll(',', '.')) > 0)
	const totalPrice = slabs.reduce((previousValue, currentValue) => {
		const priceMQ = parseFloat(String(currentValue.prezzo).replaceAll(',', '.'));
		return previousValue + (priceMQ * currentValue.quantitaUM);
	}, 0);
	const totalMQ = slabs.reduce((previousValue, currentValue) => previousValue + currentValue.quantitaUM, 0);
	const mqPrice = (totalPrice / totalMQ) || 0;
	return mqPrice.toLocaleString("it-IT", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
}

export const getTNPrice = (rows: IDettaglio[]): string => {
	const slabs = rows.filter(row => row.tipo_lotto.nome === "Blocco" && parseFloat(String(row.prezzo).replaceAll(',', '.')) > 0)
	const totalPrice = slabs.reduce((previousValue, currentValue) => {
		const priceTN = parseFloat(String(currentValue.prezzo).replaceAll(',', '.'));
		return previousValue + (priceTN * currentValue.quantitaUM);
	}, 0);
	const totalTN = slabs.reduce((previousValue, currentValue) => previousValue + currentValue.quantitaUM, 0);
	const mqPrice = (totalPrice / totalTN) || 0;
	return mqPrice.toLocaleString("it-IT", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
}

export const calcQualtityUM = (item: IDettaglio): number => {
	if (dettaglioIsBlock(item)) return item.quantitaUM;
	const height = item.misX;
	const width = item.misY;
	const num = item.pezzi;
	return height * width * num / 10000;
}

export const getTotalCosts = (rows: IDettaglio[]): string => rows.reduce((previousValue, currentValue) => {
	const costiDiretti = parseFloat(String(currentValue.costiDiretti).replaceAll(',', '.'));
	const costiPadre = parseFloat(String(currentValue.costiPadre).replaceAll(',', '.'));

	const costi = costiDiretti + costiPadre;
	return previousValue + costi;
}, 0)
	.toLocaleString("it-IT", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
