import { GridColumns, GridRenderCellParams, GridValueGetterParams } from "@mui/x-data-grid";
import { IDettaglio } from "../../../../../../interfaces/dettaglio/models";
import { ICreatePackingListColumnsGridColumnsPros } from "./interfaces";

const i18_PATH = 'prodotto.view.lottoDettagli.grids.packingListGridColums.'

const createAllColumns = (t: (path: string) => string): GridColumns<IDettaglio> => (
	[
		{
			field: 'lotto',
			headerName: t(`${i18_PATH}Lotto`),
			width: 130,
			sortable: false,
			valueGetter: (params: GridValueGetterParams<undefined, IDettaglio>) => params?.row?.lotto?.codice
		},
		{
			field: 'bundle',
			headerName: t(`${i18_PATH}Bundle`),
			type: 'number',
			sortable: false,
			renderCell: (params: GridRenderCellParams<string>) => <b>{params.formattedValue}</b>,
			width: 60
		},
		{
			field: 'lastreDa',
			headerName: t(`${i18_PATH}Da`),
			type: "number",
			sortable: false,
			valueGetter: (params: GridValueGetterParams<undefined, IDettaglio>) => (params?.row?.tipo_lotto?.nome === "Lastre") ? params?.row?.lastreDa : "-------",
			width: 60
		},
		{
			field: 'lastreA',
			headerName: t(`${i18_PATH}A`),
			type: 'number',
			sortable: false,
			valueGetter: (params: GridValueGetterParams<undefined, IDettaglio>) => (params?.row?.tipo_lotto?.nome === "Lastre") ? params?.row?.lastreA : "-------",
			width: 60
		},
		{
			field: 'pezzi',
			headerName: t(`${i18_PATH}Pezzi`),
			type: 'number',
			sortable: false,
			renderCell: (params: GridRenderCellParams<string>) => <b>{params.formattedValue}</b>,
			width: 60
		},
		{
			field: 'misX',
			headerName: t(`${i18_PATH}Lun`),
			type: 'number',
			sortable: false,
			width: 80
		},
		{
			field: 'misY',
			headerName: t(`${i18_PATH}Alt`),
			type: 'number',
			sortable: false,
			width: 80
		},
		{
			field: 'misZ',
			headerName: t(`${i18_PATH}Spes`),
			type: 'number',
			sortable: false,
			width: 80
		},
		{
			field: 'quantitaUM',
			headerName: t(`${i18_PATH}Quantità`),
			type: 'number',
			sortable: false,
			renderCell: (params: GridRenderCellParams<number>) => {
				const value = parseFloat(String(params.value).replaceAll(',', '.'));
				return Number(value).toLocaleString("it-IT", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
			},
			width: 80
		},
		{
			field: 'um',
			headerName: '',
			sortable: false,
			valueGetter: (params: GridValueGetterParams<undefined, IDettaglio>) => (params?.value) ? t(String(params.value)) : "",
			width: 15
		},
		{
			field: 'prezzo',
			headerName: t(`${i18_PATH}Prezzo`),
			type: 'number',
			sortable: false,
			renderCell: (params: GridRenderCellParams<number>) => {
				const value = parseFloat(String(params.value).replaceAll(',', '.'));
				return Number(value) ? Number(value).toLocaleString("it-IT", { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : "-------"
			},
			width: 120
		},
		{
			field: 'um_prezzo',
			headerName: '',
			sortable: false,
			valueGetter: (params: GridValueGetterParams<undefined, IDettaglio>) => (params?.row?.um) ? t(String(params.row.um)) : "",
			width: 15
		},
		{
			field: "stato_lavorazione",
			headerName: t(`${i18_PATH}Lavorazione`),
			valueGetter: (params: GridValueGetterParams<unknown, IDettaglio>) => t(params?.row?.stato_lavorazione?.nome ?? ''),
			sortable: false,
			minWidth: 120,
		},
	]
)

export const createPackingListGridColumns = (props: ICreatePackingListColumnsGridColumnsPros): GridColumns<IDettaglio> => {
	const { t, canViewCommercials, canViewStoragePlace, canViewCosts, canViewCommercialOptions } = props;
	let columns = createAllColumns(t);
	if (!canViewCosts) columns = columns.filter((col => col.field !== 'costo' && col.field !== 'um_costo'));
	if (!canViewCommercials) columns = columns.filter((col => col.field !== 'prezzo' && col.field !== 'um_prezzo'));
	if (!canViewStoragePlace) columns = columns.filter((col => col.field !== 'magazzino' && col.field !== 'zona' && col.field !== 'fila'));
	if (!canViewCommercialOptions) columns = columns.filter((col => col.field !== 'disponibilita' && col.field !== 'opzCliente' && col.field !== 'agente' && col.field !== 'opzNote'));
	return columns;
}