import axios, { AxiosProgressEvent } from "axios";
import { IBaseError } from "../../interfaces/errors";
import { ILottoImage, ILottoImageUpdate, ILottoImageUpload } from "../../interfaces/lotto/models";
import { IDeleteResponse, IListResponse, IStoreResponse, IUpdateResponse } from "../../interfaces/rest";
import { getJWT } from "../../lib/jwt";

const BASE_URL = `${process.env.REACT_APP_API_URL}image`

export const getCover = async (idLotto: number): Promise<ILottoImage> => {
	const url = `${BASE_URL}/cover/${idLotto}`;
	const headers = new Headers();
	headers.append('Content-Type', 'application/json');
	const jwt = getJWT();
	if (jwt) headers.append('Authorization', 'Bearer ' + jwt);
	const response = await fetch(url, {
		method: "GET",
		headers: headers
	});
	const json: ILottoImage | IBaseError = await response.json();
	if (response.status !== 200) throw json;
	return json as ILottoImage;
}

export const getCoverList = async (): Promise<IListResponse<ILottoImage>> => {
	const url = `${BASE_URL}/list-cover`;
	const headers = new Headers();
	headers.append('Content-Type', 'application/json');
	const jwt = getJWT();
	if (jwt) headers.append('Authorization', 'Bearer ' + jwt);
	const response = await fetch(url, {
		method: "GET",
		headers: headers,
	});
	const json: IListResponse<ILottoImage> | IBaseError = await response.json();
	if (response.status !== 200) throw json;
	return json as IListResponse<ILottoImage>;
}

export const setCover = async (id: number): Promise<IUpdateResponse> => {
	const url = `${BASE_URL}/set-cover/${id}`;
	const headers = new Headers();
	headers.append('Content-Type', 'application/json');
	const jwt = getJWT();
	if (jwt) headers.append('Authorization', 'Bearer ' + jwt);
	const response = await fetch(url, {
		method: "PUT",
		headers: headers,
	});
	const json: IUpdateResponse | IBaseError = await response.json();
	if (response.status !== 200) throw json;
	return json as IUpdateResponse;
}

export const updateImage = async (id: number, data: ILottoImageUpdate): Promise<IUpdateResponse> => {
	const url = `${BASE_URL}/${id}`;
	const headers = new Headers();
	headers.append('Content-Type', 'application/json');
	const jwt = getJWT();
	if (jwt) headers.append('Authorization', 'Bearer ' + jwt);
	const body = JSON.stringify(data);
	const response = await fetch(url, {
		method: "PUT",
		headers: headers,
		body
	});
	const json: IUpdateResponse | IBaseError = await response.json();
	if (response.status !== 200) throw json;
	return json as IUpdateResponse;
}

export const removeImage = async (id: number): Promise<IDeleteResponse> => {
	const url = `${BASE_URL}/${id}`;
	const headers = new Headers();
	headers.append('Content-Type', 'application/json');
	const jwt = getJWT();
	if (jwt) headers.append('Authorization', 'Bearer ' + jwt);
	const response = await fetch(url, {
		method: "DELETE",
		headers: headers
	});
	const json: IDeleteResponse | IBaseError = await response.json();
	if (response.status !== 200) throw json;
	return json as IDeleteResponse;
}

export const uploadImage = async (data: ILottoImageUpload, onUploadProgress: (progress: AxiosProgressEvent) => void = () => null) => {
	const { idLotto, image, isBlock } = data;
	const _isBlock = (!!isBlock) ? 1 : 0;
	const url = `${BASE_URL}`;
	const headers: Record<string, string> = {
		'Content-Type': 'multipart/form-data'
	};
	const jwt = getJWT();
	if (jwt) headers['Authorization'] = 'Bearer ' + jwt;
	const formData = new FormData();
	formData.append('idLotto', String(idLotto));
	formData.append('isBlock', String(_isBlock));
	formData.append('image', image);
	const response = await axios.request({
		method: "post",
		url,
		headers,
		data: formData,
		onUploadProgress
	})
	const json: IStoreResponse | IBaseError = response.data;
	if (response.status !== 200) throw json;
	return json as IStoreResponse;
}