import { IAutenticate } from "../../interfaces/auth/models";
import { IAuthenticateError } from "../../interfaces/errors";
import { IUser } from "../../interfaces/user/models";
import { getJWT } from "../../lib/jwt";

const BASE_URL = `${process.env.REACT_APP_API_URL}auth`

export const login = async (email: string, password: string): Promise<IAutenticate | null> => {
	const url = `${BASE_URL}/login`;
	const headers = new Headers();
	headers.append('Content-Type', 'application/json');
	const data = {
		email,
		password
	};
	const response = await fetch(url, {
		method: "POST",
		headers: headers,
		body: JSON.stringify(data)
	});
	const json: IAutenticate | IAuthenticateError = await response.json();
	if (response.status !== 200) throw json;
	return json as IAutenticate | null;
}

export const authenticate = async (): Promise<IUser | false> => {
	const url = `${BASE_URL}/authenticate`;
	const headers = new Headers();
	headers.append('Content-Type', 'application/json');
	const jwt = getJWT();
	if (jwt) headers.append('Authorization', 'Bearer ' + jwt);
	const response = await fetch(url, {
		method: "GET",
		headers: headers
	});
	const json = await response.json();
	return json as IUser | false;
}
