import { IBaseError } from "../../interfaces/errors";
import { IProspect, IProspectStore, IProspectUpdate } from "../../interfaces/prospect/models";
import { IDeleteResponse, IListResponse, IStoreResponse, IUpdateResponse } from "../../interfaces/rest";
import { getJWT } from "../../lib/jwt";

const BASE_URL = `${process.env.REACT_APP_API_URL}prospect`

export const getProspect = async (id: number): Promise<IProspect> => {
	const url = `${BASE_URL}/${id}`;
	const headers = new Headers();
	headers.append('Content-Type', 'application/json');
	const jwt = getJWT();
	if (jwt) headers.append('Authorization', 'Bearer ' + jwt);
	const response = await fetch(url, {
		method: "GET",
		headers: headers
	});
	const json: IProspect | IBaseError = await response.json();
	if (response.status !== 200) throw json;
	return json as IProspect;
}

export const getProspectList = async (): Promise<IListResponse<IProspect>> => {
	const url = `${BASE_URL}/list`;
	const headers = new Headers();
	headers.append('Content-Type', 'application/json');
	const jwt = getJWT();
	if (jwt) headers.append('Authorization', 'Bearer ' + jwt);
	const response = await fetch(url, {
		method: "GET",
		headers: headers
	});
	const json: IListResponse<IProspect> | IBaseError = await response.json();
	if (response.status !== 200) throw json;
	return json as IListResponse<IProspect>;
}

export const storeProspect = async (data: IProspectStore): Promise<IStoreResponse> => {
	const url = `${BASE_URL}`;
	const headers = new Headers();
	headers.append('Content-Type', 'application/json');
	const jwt = getJWT();
	if (jwt) headers.append('Authorization', 'Bearer ' + jwt);
	const body = JSON.stringify(data);
	const response = await fetch(url, {
		method: "POST",
		headers: headers,
		body
	});
	const json: IStoreResponse | IBaseError = await response.json();
	if (response.status !== 200) throw json;
	return json as IStoreResponse;
}

export const updateProspect = async (id: number, data: IProspectUpdate): Promise<IUpdateResponse> => {
	const url = `${BASE_URL}/${id}`;
	const headers = new Headers();
	headers.append('Content-Type', 'application/json');
	const jwt = getJWT();
	if (jwt) headers.append('Authorization', 'Bearer ' + jwt);
	const body = JSON.stringify(data);
	const response = await fetch(url, {
		method: "PUT",
		headers: headers,
		body
	});
	const json: IUpdateResponse | IBaseError = await response.json();
	if (response.status !== 200) throw json;
	return json as IUpdateResponse;
}

export const removeProspect = async (id: number): Promise<IDeleteResponse> => {
	const url = `${BASE_URL}/${id}`;
	const headers = new Headers();
	headers.append('Content-Type', 'application/json');
	const jwt = getJWT();
	if (jwt) headers.append('Authorization', 'Bearer ' + jwt);
	const response = await fetch(url, {
		method: "DELETE",
		headers: headers
	});
	const json: IDeleteResponse | IBaseError = await response.json();
	if (response.status !== 200) throw json;
	return json as IDeleteResponse;
}
