import { createAction } from '@reduxjs/toolkit';
import { IQualita } from '../../../../interfaces/lotto/attributes/models';
import { EMPTY, GET, IQualitaState, LIST } from './types';

export const listAction = createAction<IQualitaState>(LIST);

export const getAction = createAction<IQualita>(GET);

export const emptyAction = createAction(EMPTY);

