export const getJWT = (): string | null => {
	const jwt = localStorage.getItem('JWT');
	if (!!jwt) return jwt;
	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);
	const tempJwt = urlParams.get('token');
	if (!!tempJwt) return tempJwt;
	return null;
}

export const isUnknownJWT = (): boolean => {
	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);
	const tempJwt = urlParams.get('token');
	return !!tempJwt;
}

export const setJWT = (jwt: string): void => localStorage.setItem('JWT', jwt);

export const emptyJWT = (): void => localStorage.removeItem('JWT');