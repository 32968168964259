import * as React from 'react';
import { errorHandler } from '../../error/handler';
import { getCoverList } from '../../fetch/image';
import { getLotto, getLottoList, shareLotto, updateLotto } from '../../fetch/lotto/lotto';
import { LottoShareFormData, LottoUpdateFormData } from '../../interfaces/lotto/forms';
import { ILotto, ILottoImage } from '../../interfaces/lotto/models';
import { calcQualtityUM } from '../../lib/functions';
import { normalizer, normalizerTransform } from '../../lib/normalizer';
import { lottoTransform } from '../../lib/transform';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { clearShareTokenAction, emptyAction, getAction, getShareTokenAction, listAction } from '../../store/lotto/lotto/actions';
import { ILottoState } from '../../store/lotto/lotto/types';
import { endActionToDoAction, messageAction, startActionToDoAction } from '../../store/ui/actions';

export const useLottoGet = (id: number | null, query: number) => {
	const dispatch = useAppDispatch();
	React.useEffect(() => {
		const effect = async () => {
			if (!(!!id && query)) return;
			const actionToDo = 'get-lotto';
			try {
				dispatch(startActionToDoAction({ actionToDo }));
				const lotto = await getLotto(id);
				const dettagli = lotto.dettagli?.filter(Boolean).map(dettaglio => ({ ...dettaglio, quantitaUM: calcQualtityUM(dettaglio) }));
				const images = lotto.images?.filter(Boolean);
				dispatch(getAction({ ...lotto, dettagli, images }));
				dispatch(endActionToDoAction({ actionToDo, success: true }));
			} catch (err) {
				errorHandler(err, actionToDo);
			}
		}
		effect();
	}, [dispatch, id, query])
}

export const useLottoList = (query: number) => {
	const dispatch = useAppDispatch();
	const categoria = useAppSelector((state) => state.lotto_categoria.items);
	const fondo = useAppSelector((state) => state.lotto_fondo.items);
	const materiale = useAppSelector((state) => state.lotto_materiale.items);
	const qualita = useAppSelector((state) => state.lotto_qualita.items);
	const uso = useAppSelector((state) => state.lotto_uso.items);
	const venatura = useAppSelector((state) => state.lotto_venatura.items);
	const cava = useAppSelector((state) => state.place_cava.items);
	React.useEffect(() => {
		const effect = async () => {
			if (!(!!query && !!categoria && !!fondo && !!materiale && !!qualita && !!uso && !!venatura && !!cava)) return;
			const actionToDo = 'list-lotto';
			try {
				dispatch(startActionToDoAction({ actionToDo }));
				const { results, items: _items } = await getLottoList();
				const { items: _cover } = await getCoverList();
				const { items: cover } = normalizer<number, ILottoImage>(_cover, "idLotto");
				const { list, items } = normalizerTransform<number, ILotto, ILotto>(_items, (item) => lottoTransform(item, cava, categoria, fondo, materiale, qualita, uso, venatura, cover));
				const actionPayload: ILottoState = {
					results,
					list,
					items,
					shareToken: null
				}
				dispatch(listAction(actionPayload));
				dispatch(endActionToDoAction({ actionToDo, success: true }));
			} catch (err) {
				errorHandler(err, actionToDo);
			}
			return () => {
				dispatch(emptyAction());
			}
		}
		effect();
	}, [dispatch, query, categoria, fondo, materiale, qualita, uso, venatura, cava])
}

export const useLottoUpdate = (id: number | null, data: LottoUpdateFormData) => {
	const dispatch = useAppDispatch();
	React.useEffect(() => {
		const effect = async () => {
			if (!id || !data) return;
			const actionToDo = 'update-lotto';
			try {
				dispatch(startActionToDoAction({ actionToDo }));
				await updateLotto(id, data);
				dispatch(endActionToDoAction({ actionToDo, success: true }));
				dispatch(messageAction({ message: "Lotto aggiornato", kind: "success" }));
			} catch (err) {
				errorHandler(err, actionToDo);
			}
		}
		effect();
	}, [dispatch, id, data]);
}

export const useLottoShare = (data: LottoShareFormData) => {
	const dispatch = useAppDispatch();
	React.useEffect(() => {
		const effect = async () => {
			if (!data) return;
			const actionToDo = 'share-lotto';
			try {
				dispatch(startActionToDoAction({ actionToDo }));
				const { token } = await shareLotto(data);
				dispatch(getShareTokenAction(token));
				dispatch(endActionToDoAction({ actionToDo, success: true }));
			} catch (err) {
				errorHandler(err, actionToDo);
			}
		}
		effect();
	}, [dispatch, data]);
}

export const useLottoClearShare = (query: number) => {
	const dispatch = useAppDispatch();
	React.useEffect(() => {
		const effect = async () => {
			const actionToDo = 'clear-share-lotto';
			if (!query) return;
			try {
				dispatch(startActionToDoAction({ actionToDo }));
				dispatch(clearShareTokenAction());
				dispatch(endActionToDoAction({ actionToDo, success: true }));
			} catch (err) {
				errorHandler(err, actionToDo);
			}
		}
		effect();
	}, [dispatch, query]);
}