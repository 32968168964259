import * as React from 'react';
import { errorHandler } from '../../../error/handler';
import { getMateriale, getMaterialeList } from '../../../fetch/lotto/attributes/materiale';
import { IMateriale } from '../../../interfaces/lotto/attributes/models';
import { normalizer } from '../../../lib/normalizer';
import { useAppDispatch } from '../../../store/hooks';
import { emptyAction, getAction, listAction } from '../../../store/lotto/attributes/materiale/actions';
import { IMaterialeState } from '../../../store/lotto/attributes/materiale/types';
import { endActionToDoAction, startActionToDoAction } from '../../../store/ui/actions';

export const useGetMateriale = (id: number) => {
	const dispatch = useAppDispatch();
	React.useEffect(() => {
		const effect = async () => {
			if (!id) return;
			const actionToDo = 'get-materiale';
			try {
				dispatch(startActionToDoAction({ actionToDo }));
				const materiale = await getMateriale(id);
				dispatch(getAction(materiale));
				dispatch(endActionToDoAction({ actionToDo, success: true }));
			} catch (err) {
				errorHandler(err, actionToDo);
			}
		}
		effect();
	}, [dispatch, id])
}

export const useMaterialeList = (query: number) => {
	const dispatch = useAppDispatch();
	React.useEffect(() => {
		const effect = async () => {
			if (!query) return;
			const actionToDo = 'list-materiale';
			try {
				dispatch(startActionToDoAction({ actionToDo }));
				const { results, items: _items } = await getMaterialeList();
				_items.sort((itemA, itemB) => (!!itemA?.ordinamento && !!itemB.ordinamento) ? itemA.ordinamento - itemB.ordinamento : 0);
				const { list, items } = normalizer<number, IMateriale>(_items, "id");
				const actionPayload: IMaterialeState = {
					results,
					list,
					items
				}
				dispatch(listAction(actionPayload));
				dispatch(endActionToDoAction({ actionToDo, success: true }));
			} catch (err) {
				errorHandler(err, actionToDo);
			}
			return () => {
				dispatch(emptyAction());
			}
		}
		effect();
	}, [dispatch, query])
}
