import { Collapse, Icon, List, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { useEffect, useState } from "react";
import { EOptionType } from "../../../../../../../interfaces/enums";
import { isFilterAndValueSelected } from "../../../../../../../lib/filters";
import { useAppSelector } from "../../../../../../../store/hooks";
import { IFilterProps } from "../../interfaces";

function ClientiFilter({ filters, setFilters }: IFilterProps) {

	const [open, setOpen] = useState<boolean>(false);
	const [list, setList] = useState<string[]>([]);

	const dettaglioItems = useAppSelector((state) => state.dettaglio.items || {});

	const toggle = () => {
		setOpen(!open);
	}

	const toggleSelected = (item: string) => {
		if (isFilterAndValueSelected(filters, EOptionType.cliente, item)) {
			setFilters(filters.filter(opt => !(opt.type === EOptionType.cliente)));
		} else {
			setFilters([
				...filters.filter(opt => !(opt.type === EOptionType.cliente)),
				{
					type: EOptionType.cliente,
					stringValue: item
				}
			]);
		}
	}
	useEffect(() => {
		const idDisponiblilita = filters.find(f => f.type === EOptionType.disponibilita && !!f.numericValue)?.numericValue as number;
		const clientiArray = Object.values(dettaglioItems).filter(dettaglio => idDisponiblilita === dettaglio.idDisponibilita as number).map(dettaglio => dettaglio.opzCliente as string).filter(Boolean);
		const clientiSet = new Set(clientiArray);
		const clienti = Array.from(clientiSet);
		clienti.sort((a, b) => (a > b ? 1 : -1));
		setList(clienti);
	}, [filters, dettaglioItems])
	return (
		<>
			<ListItemButton onClick={toggle}>
				<ListItemIcon>
					<Icon className="fa-light fa-users" sx={{ overflow: "visible" }} />
				</ListItemIcon>
				<ListItemText primary="Clienti" />
				{open ? <Icon className="fa-light fa-angle-up" fontSize="small" sx={{ overflow: "visible" }} /> : <Icon className="fa-light fa-angle-down" fontSize="small" sx={{ overflow: "visible" }} />}
			</ListItemButton>
			<Collapse in={open} timeout="auto" unmountOnExit>
				<List dense={true}>
					{list.map(item => (
						<ListItemButton key={item} sx={{ display: "flex", pl: 5, pr: 1 }} onClick={() => toggleSelected(item)} selected={isFilterAndValueSelected(filters, EOptionType.cliente, item)}>
							<ListItemText sx={{ flex: 1 }} primary={item} />
							<ListItemIcon sx={{ minWidth: 0 }}>
								{isFilterAndValueSelected(filters, EOptionType.cliente, item) && <Icon className="fa-light fa-check" sx={{ overflow: "visible" }} />}
							</ListItemIcon>
						</ListItemButton>
					))}
				</List>
			</Collapse>
		</>
	)
}

export default ClientiFilter;