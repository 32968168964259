import { List, ListSubheader } from "@mui/material";
import { useEffect, useState } from "react";
import { EOptionType } from "../../../../../../../interfaces/enums";
import { IFilterProps } from "../../interfaces";
import ClientiFilter from "./clienti";
import DisponibilitaFilter from "./disponibilita";
import PrezzoFilter from "./prezzo";

function CommercialeFiltersContainer({ filters, setFilters }: IFilterProps) {
	const [showClienti, setShowClienti] = useState<boolean>(false);
	useEffect(() => {
		const found = !!filters.find(f => f.type === EOptionType.disponibilita && [1, 2].includes(f.numericValue as number));
		if (found) setShowClienti(true);
		else setShowClienti(false);
	}, [filters])
	return (
		<List
			dense={true}
			sx={{ p: 0 }}
			component="nav"
			subheader={
				<ListSubheader component="div">
					Commerciale
				</ListSubheader>
			}
		>
			<DisponibilitaFilter
				filters={filters}
				setFilters={setFilters}
			/>
			{showClienti &&
				<ClientiFilter
					filters={filters}
					setFilters={setFilters}
				/>
			}
			<PrezzoFilter
				filters={filters}
				setFilters={setFilters}
			/>
		</List>
	)
}

export default CommercialeFiltersContainer;