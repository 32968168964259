
import { BottomNavigation, BottomNavigationAction, Box, Icon, Paper } from "@mui/material";
import i18next from "i18next";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useLottoGet } from "../../../effects/lotto/lotto";
import { IDettaglio } from "../../../interfaces/dettaglio/models";
import { ILotto } from "../../../interfaces/lotto/models";
import { PostMessageViewWindowReady } from "../../../interfaces/postMessage/types";
import { useAppSelector } from "../../../store/hooks";
import LottoDettagli from "./components/lottoDettagli/lottoDettagli";
import LottoImages from "./components/lottoImages/lottoImages";
import LottoMain from "./components/lottoMain/lottoMain";

const sendReadyMessage = (parentWindow: Window | null) => {
	if (parentWindow === null || parentWindow?.closed) return;

	const message: PostMessageViewWindowReady = {
		type: "GEMEG_VIEW_READY",
	}
	parentWindow.postMessage(message);
}

function LottoView() {
	const { lottoId } = useParams();
	const id = (!!lottoId) ? parseInt(lottoId) : null;

	const [query, setQuery] = useState<number>(1);
	const [parentWindow, setParentWindow] = useState<Window | null>(null);
	const [lottoIds, setLottoIds] = useState<number[]>([]);
	const [lotto, setLotto] = useState<ILotto | null>();
	const [selectedItems, setSelectedItems] = useState<IDettaglio[]>([]);
	const [isNotAnonymousUser, setIsNotAnonymousUser] = useState<boolean>(false);

	const imagesContainer = useRef<HTMLElement>();
	const imagesBlockContainer = useRef<HTMLElement>();

	const lastActionDone = useAppSelector((state) => state.ui.lastActionDone);
	const lottoItems = useAppSelector((state) => state.lotto.items);
	const user = useAppSelector((state) => state.auth.user);


	const messageListener = (event: MessageEvent) => {
		const { data } = event;
		if (data.type === 'GEMEG_DETTAGLI_LIST') {
			setLottoIds(data.lottoIds || []);
		}
	}
	const scrollToImages = () => imagesContainer?.current?.scrollIntoView({ behavior: 'smooth' });
	const next = () => {
		if (!id) return;
		const index = lottoIds.indexOf(id);
		if (index < lottoIds.length - 1) {
			const nextLottoId = lottoIds[index + 1];
			window.location.href = `/#/prodotti/${nextLottoId}`;
		}

	}
	const prev = () => {
		if (!id) return;
		const index = lottoIds.indexOf(id);
		if (index > 0) {
			const nextLottoId = lottoIds[index - 1];
			window.location.href = `/#/prodotti/${nextLottoId}`;
		}

	}
	useLottoGet(id, query);
	useEffect(() => {
		if (!!lastActionDone && [
			'split-dettaglio',
			'update-commercial-options-dettaglio',
			'update-lotto',
			'update-image', 'set-cover-image', 'remove-immagine', 'upload-immagine', 'upload-immagini'
		].includes(lastActionDone)) {
			setQuery(query + 1);
		}
	}, [lastActionDone, query]);
	useEffect(() => {
		if (parentWindow !== null) sendReadyMessage(parentWindow);
	}, [parentWindow])
	useEffect(() => {

		const _lotto = (!!id && !!lottoItems) ? lottoItems[id] : null;
		setLotto(_lotto);
	}, [lottoItems, id])
	useEffect(() => {
		const parent = window.opener;
		setParentWindow(parent);
		window.addEventListener('message', messageListener)
	}, [])
	useEffect(() => {
		if (!!user?.unknown) i18next.changeLanguage("en");
		const _isNotAnonymousUser = !!user && !user.unknown;
		setIsNotAnonymousUser(_isNotAnonymousUser);
	}, [user])
	return (
		<Box sx={{ pb: !!lottoIds.length ? 8 : 2, backgroundColor: "#fff" }}>
			{!!lotto &&
				<LottoMain
					lotto={lotto}
					user={user}
					view={scrollToImages}
				/>}
			{!!lotto &&
				<LottoDettagli
					lotto={lotto}
					dettagli={lotto.dettagli || []}
					selectedItems={selectedItems}
					setSelectedItems={setSelectedItems}
					user={user}
				/>
			}
			{(!!lotto && !!isNotAnonymousUser) &&
				<Box ref={imagesBlockContainer} >
					<LottoImages
						lotto={lotto}
						isBlock={true}
						dettagli={lotto.dettagli || []}
						selectedItems={selectedItems}
						user={user}
					/>
				</Box>
			}
			{!!lotto &&
				<Box ref={imagesContainer} >
					<LottoImages
						lotto={lotto}
						dettagli={lotto.dettagli || []}
						selectedItems={selectedItems}
						user={user}
					/>
				</Box>
			}
			{!!lottoIds.length &&
				<Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
					<BottomNavigation
					>
						<BottomNavigationAction onClick={prev} icon={<Icon className="fa-light fa-left" sx={{ overflow: "visible" }} />} />
						<BottomNavigationAction onClick={next} icon={<Icon className="fa-light fa-right" sx={{ overflow: "visible" }} />} />
					</BottomNavigation>
				</Paper>
			}
		</Box>
	)
}

export default LottoView;