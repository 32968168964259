import { IQualita } from "../../../../interfaces/lotto/attributes/models";

export const GET = "lotto_qualita/get";
export const LIST = "lotto_qualita/list";
export const EMPTY = "lotto_qualita/empty";

export interface IQualitaState {
	results: number;
	list: number[];
	items: Record<number, IQualita> | null;
};

export const initialState: IQualitaState = {
	results: 0,
	list: [],
	items: null
};