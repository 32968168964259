import { createAction } from '@reduxjs/toolkit';
import { IMateriale } from '../../../../interfaces/lotto/attributes/models';
import { EMPTY, GET, IMaterialeState, LIST } from './types';

export const listAction = createAction<IMaterialeState>(LIST);

export const getAction = createAction<IMateriale>(GET);

export const emptyAction = createAction(EMPTY);

