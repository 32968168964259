import { IBaseError } from "../../../interfaces/errors";
import { IFondo } from "../../../interfaces/lotto/attributes/models";
import { IListResponse } from "../../../interfaces/rest";
import { getJWT } from "../../../lib/jwt";


const BASE_URL = `${process.env.REACT_APP_API_URL}fondo`;

export const getFondo = async (id: number): Promise<IFondo> => {
	const url = `${BASE_URL}/${id}`;
	const headers = new Headers();
	headers.append('Content-Type', 'application/json');
	const jwt = getJWT();
	if (jwt) headers.append('Authorization', 'Bearer ' + jwt);
	const response = await fetch(url, {
		method: "GET",
		headers: headers
	});
	const json: IFondo | IBaseError = await response.json();
	if (response.status !== 200) throw json;
	return json as IFondo;
}

export const getFondoList = async (): Promise<IListResponse<IFondo>> => {
	const url = `${BASE_URL}/list`;
	const headers = new Headers();
	headers.append('Content-Type', 'application/json');
	const jwt = getJWT();
	if (jwt) headers.append('Authorization', 'Bearer ' + jwt);
	const response = await fetch(url, {
		method: "GET",
		headers: headers
	});
	const json: IListResponse<IFondo> | IBaseError = await response.json();
	if (response.status !== 200) throw json;
	return json as IListResponse<IFondo>;
}
