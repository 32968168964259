import { AppBar, Box, Button, Dialog, DialogActions, Icon, IconButton, Tab, Tabs, Toolbar, Typography } from '@mui/material';
import { ElementRef, SyntheticEvent, useEffect, useRef, useState } from 'react';
import Transition from '../../../../../../../lib/dialogTransition';
import { IUpdateDialogProps } from '../interfaces';
import CommercialsForm from './commercialsForm';
import NotesForm from './notesForm';
import ProcessingStatusForm from './processingStatusForm';
import StorageForm from './storageForm';

type CommercialsFormRef = ElementRef<typeof CommercialsForm>;
type StorageFormRef = ElementRef<typeof StorageForm>;
type ProcessingStatusFormRef = ElementRef<typeof ProcessingStatusForm>;
type NotesFormRef = ElementRef<typeof NotesForm>;

function DettaglioUpdateDialog({ isOpen, lottoId, selectedItems, canSetCommercials, canSetCommercialOptions, canSetStoragePlace, canSetFeatures, onClose }: IUpdateDialogProps) {
	const commercialFormRef = useRef<CommercialsFormRef>(null);
	const storageFormRef = useRef<StorageFormRef>(null);
	const processingStatusFormRef = useRef<ProcessingStatusFormRef>(null);
	const notesFormRef = useRef<NotesFormRef>(null);
	const [title, setTitle] = useState<string>("");
	const [selectedTab, setSelectedTab] = useState<number>(0);
	const handleTabChange = (_: SyntheticEvent, newValue: number) => {
		setSelectedTab(newValue);
	}
	const save = () => {
		commercialFormRef?.current?.onSubmit();
		storageFormRef?.current?.onSubmit();
		processingStatusFormRef?.current?.onSubmit();
		notesFormRef?.current?.onSubmit();
	}
	const close = () => {
		onClose()
	}
	useEffect(() => {
		if (selectedItems.length === 1) {
			setTitle("Modifica il dettaglio selezionato");
		} else if (selectedItems.length > 1) {
			setTitle("Modifica tutti i dettagli selezionati");
		} else {
			setTitle("Nessun dettaglio selezionato");
		}
		setSelectedTab(0);
	}, [selectedItems])
	return (
		<Dialog
			open={isOpen}
			onClose={close}
			TransitionComponent={Transition}
			fullWidth
			maxWidth="md"
		>
			<AppBar sx={{ position: 'relative' }}>
				<Toolbar variant="dense">
					<Typography variant="h5" sx={{ flex: 1 }} display="block" >{title}</Typography>
					<IconButton
						edge="end"
						color="inherit"
						onClick={close}
						aria-label="close"
					>
						<Icon className="fa-light fa-xmark" sx={{ overflow: "visible" }} />
					</IconButton>
				</Toolbar>
			</AppBar>
			<Box sx={{ p: 2, overflowY: 'auto' }}>
				<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
					<Tabs
						value={selectedTab}
						onChange={handleTabChange}
						aria-label="update-tabs"
					>
						{canSetCommercials &&
							<Tab
								label="Misure e informazioni commerciali"
								id="update-tab-0"
								aria-controls="update-tab-0"
							/>
						}
						{canSetStoragePlace &&
							<Tab
								label="Posizione"
								id="update-tab-1"
								aria-controls="update-tab-1"
							/>
						}
						{canSetFeatures &&
							<Tab
								label="Lavorazione"
								id="update-tab-2"
								aria-controls="update-tab-2"
							/>
						}
						{canSetCommercialOptions &&
							<Tab
								label="Note"
								id="update-tab-3"
								aria-controls="update-tab-3"
							/>
						}
					</Tabs>
				</Box>
				{canSetCommercials &&
					<CommercialsForm
						hidden={selectedTab !== 0}
						lottoId={lottoId}
						selectedItems={selectedItems}
						ref={commercialFormRef}
					></CommercialsForm>
				}
				{canSetStoragePlace &&
					<StorageForm
						hidden={selectedTab !== 1}
						lottoId={lottoId}
						selectedItems={selectedItems}
						ref={storageFormRef}
					></StorageForm>
				}
				{canSetFeatures &&
					<ProcessingStatusForm
						hidden={selectedTab !== 2}
						lottoId={lottoId}
						selectedItems={selectedItems}
						ref={processingStatusFormRef}
					></ProcessingStatusForm>
				}
				{canSetCommercialOptions &&
					<NotesForm
						hidden={selectedTab !== 3}
						lottoId={lottoId}
						selectedItems={selectedItems}
						ref={notesFormRef}
					></NotesForm>
				}
			</Box>
			<DialogActions>
				<Button onClick={close}>Cancella</Button>
				<Button onClick={save} variant="contained" color="primary">Salva</Button>
			</DialogActions>
		</Dialog>
	)
}

export default DettaglioUpdateDialog;