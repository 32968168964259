import { IDettaglio } from "../../../interfaces/dettaglio/models";

export const GET = "dettaglio/get";
export const LIST = "dettaglio/list";
export const EMPTY = "dettaglio/empty";

export interface IDettaglioState {
	results: number;
	list: number[];
	items: Record<number, IDettaglio> | null;
};

export const initialState: IDettaglioState = {
	results: 0,
	list: [],
	items: null
};