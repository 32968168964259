import { IDettaglio } from "../interfaces/dettaglio/models";

type DettaglioKey = string;
const createDettaglioGroupKey = (dettaglio: IDettaglio): DettaglioKey => {
	return `idLotto${dettaglio.idLotto};
		suffissoCodice${dettaglio.suffissoCodice};
		idTipoLotto${dettaglio.idTipoLotto};
		idMagazzino${dettaglio.idMagazzino};
		zona${dettaglio.zona};
		idDisponibilita${dettaglio.idDisponibilita};
		opzCliente${dettaglio.opzCliente};`;
}

const mergeDettagli = (oldValue: IDettaglio, newValue: IDettaglio): IDettaglio => {
	const pezzi = oldValue.pezzi + newValue.pezzi;
	const quantitaUM = oldValue.quantitaUM + newValue.quantitaUM;
	const mc = oldValue.mc + newValue.mc;
	const mq = oldValue.mq + newValue.mq;
	const kg = oldValue.kg + newValue.kg;
	const prezzo = ((oldValue.prezzo * oldValue.quantitaUM) + (newValue.prezzo * newValue.quantitaUM)) / quantitaUM;
	return {
		...oldValue,
		pezzi,
		quantitaUM,
		mc,
		mq,
		kg,
		prezzo
	}
}

export const dettaglioGrouping = (dettaglioList: IDettaglio[]): IDettaglio[] => {
	const dettaglioMap: Record<DettaglioKey, IDettaglio> = {};
	for (const dettaglio of dettaglioList) {
		const dettaglioKey = createDettaglioGroupKey(dettaglio);
		if (!!dettaglioMap[dettaglioKey]) dettaglioMap[dettaglioKey] = mergeDettagli(dettaglioMap[dettaglioKey], dettaglio);
		else dettaglioMap[dettaglioKey] = dettaglio;
	}
	return Object.values(dettaglioMap);
}
