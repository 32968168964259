import { IBaseError } from "../../../interfaces/errors";
import { IQualita } from "../../../interfaces/lotto/attributes/models";
import { IListResponse } from "../../../interfaces/rest";
import { getJWT } from "../../../lib/jwt";


const BASE_URL = `${process.env.REACT_APP_API_URL}qualita`;

export const getQualita = async (id: number): Promise<IQualita> => {
	const url = `${BASE_URL}/${id}`;
	const headers = new Headers();
	headers.append('Content-Type', 'application/json');
	const jwt = getJWT();
	if (jwt) headers.append('Authorization', 'Bearer ' + jwt);
	const response = await fetch(url, {
		method: "GET",
		headers: headers
	});
	const json: IQualita | IBaseError = await response.json();
	if (response.status !== 200) throw json;
	return json as IQualita;
}

export const getQualitaList = async (): Promise<IListResponse<IQualita>> => {
	const url = `${BASE_URL}/list`;
	const headers = new Headers();
	headers.append('Content-Type', 'application/json');
	const jwt = getJWT();
	if (jwt) headers.append('Authorization', 'Bearer ' + jwt);
	const response = await fetch(url, {
		method: "GET",
		headers: headers
	});
	const json: IListResponse<IQualita> | IBaseError = await response.json();
	if (response.status !== 200) throw json;
	return json as IListResponse<IQualita>;
}
