import * as React from 'react';
import { authenticate, login } from '../../fetch/auth';
import { LoginFormData } from '../../interfaces/auth/forms';
import { IAuthenticateError } from '../../interfaces/errors';
import { emptyJWT, isUnknownJWT, setJWT } from '../../lib/jwt';
import { errorAction, getAction, getUnknownAction } from '../../store/auth/actions';
import { useAppDispatch } from '../../store/hooks';
import { endActionToDoAction, startActionToDoAction } from '../../store/ui/actions';

export const useLogin = (data: LoginFormData) => {
	const dispatch = useAppDispatch();
	React.useEffect(() => {
		const effect = async () => {
			if (!data) return;
			const actionToDo = 'auth-login';
			try {
				dispatch(startActionToDoAction({ actionToDo }));
				const { email, password } = data;
				const answer = await login(email, password);
				if (answer) {
					setJWT(answer.token);
					dispatch(getAction(answer.user));
					dispatch(endActionToDoAction({ actionToDo, success: true }));
				}
			} catch (error: any) {
				const authError = error as IAuthenticateError;
				dispatch(errorAction(authError));
				dispatch(endActionToDoAction({ actionToDo, success: false }));
			}
		}
		effect();
	}, [dispatch, data]);
}

export const useAuthenticate = () => {
	const dispatch = useAppDispatch();
	React.useEffect(() => {
		const effect = async () => {
			const actionToDo = 'auth-authenticate';
			try {
				dispatch(startActionToDoAction({ actionToDo }));
				const user = await authenticate();
				if (user) {
					if (isUnknownJWT()) dispatch(getUnknownAction(user));
					else dispatch(getAction(user));
					dispatch(endActionToDoAction({ actionToDo, success: true }));
				} else {
					if (isUnknownJWT()) dispatch(getUnknownAction(null));
					else dispatch(getAction(null));
					dispatch(endActionToDoAction({ actionToDo, success: false }));
				}
			} catch (error: any) {
				dispatch(endActionToDoAction({ actionToDo, success: false }));
			}
		}
		effect();
	}, [dispatch]);
}


export const useLogout = () => {
	const dispatch = useAppDispatch();
	React.useEffect(() => {
		const effect = async () => {
			dispatch(getAction(null));
			emptyJWT();
		}
		effect();
	}, [dispatch]);
}