import { IDettaglio } from "../../../../../interfaces/dettaglio/models";
import { ILotto } from "../../../../../interfaces/lotto/models";
import { IUser } from "../../../../../interfaces/user/models";

export enum EShowColumnState {
	'SHOW_ALL' = 'SHOW_ALL',
	'HIDE_ALL' = 'HIDE_ALL',
	'HIDE_PARTIAL' = 'HIDE_PARTIAL'
}

export interface IDettaglioExtendedNode extends IDettaglio {
	children?: IDettaglioExtendedNode[]
}

export interface IDettaglioTreeNode extends IDettaglio {
	leaf: boolean;
	depth: number;
}

export interface ICellTreeNodeParams {
	id: number;
	leaf?: boolean;
	depth?: number;
	tipo?: string;
	onOpen: (id: number) => void;
	onClose: (id: number) => void;
	isOpen: boolean;
}

export interface ICellVendutoParams {
	value: number;
}

export interface ILottoDettagliProps {
	lotto: ILotto;
	dettagli: IDettaglio[];
	selectedItems: IDettaglio[];
	setSelectedItems: (selectedItems: IDettaglio[]) => void
	user: IUser | null;
}

export interface IDettagliSelectViewToolbarProps {
	mode: "tree" | "flat";
	onSetMode: (display: "tree" | "flat") => void;
}

export interface IDettagliShowHideColumnsToolbarProps {
	showColumns: EShowColumnState;
	onPartialHide: () => void;
	onHide: () => void;
	onShow: () => void;
}