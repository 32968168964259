import { GridColumns, GridRenderCellParams, GridValueGetterParams } from "@mui/x-data-grid";
import { IDettaglio } from "../../../../../../interfaces/dettaglio/models";
import { CellTipoLotto } from "../../../../shared/tableCells";
import { ICreateDefaultGridColumnsPros } from "./interfaces";

const i18_PATH = 'prodotto.view.lottoDettagli.grids.defaultGridColums.'

const createAllColumns = (t: (path: string) => string): GridColumns<IDettaglio> => (
	[
		{
			field: 'tipoLotto',
			headerName: t(`${i18_PATH}Tipo`),
			type: 'actions',
			sortable: false,
			renderCell: (params: GridRenderCellParams<string>) => <CellTipoLotto dettaglio={params.row} />
		},
		{
			field: 'magazzino',
			headerName: t(`${i18_PATH}Magazzino`),
			sortable: false,
			valueGetter: (params: GridValueGetterParams<unknown, IDettaglio>) => params?.row?.magazzino?.nome,
			width: 120
		},
		{
			field: 'zona',
			headerName: t(`${i18_PATH}Zona`),
			sortable: false,
			valueGetter: (params: GridValueGetterParams<unknown, IDettaglio>) => params?.row?.zona?.nome,
			width: 80
		},
		{
			field: 'fila',
			headerName: t(`${i18_PATH}Fila`),
			sortable: false,
			valueGetter: (params: GridValueGetterParams<unknown, IDettaglio>) => params?.row?.fila?.nome,
			width: 80
		},
		{
			field: 'bundle',
			headerName: t(`${i18_PATH}Bundle`),
			type: 'number',
			sortable: false,
			renderCell: (params: GridRenderCellParams<string>) => <b>{params.formattedValue}</b>,
			width: 60
		},
		{
			field: 'lastreDa',
			headerName: t(`${i18_PATH}Da`),
			type: "number",
			sortable: false,
			valueGetter: (params: GridValueGetterParams<undefined, IDettaglio>) => (params?.row?.tipo_lotto?.nome === "Lastre") ? params?.row?.lastreDa : "-------",
			width: 60
		},
		{
			field: 'lastreA',
			headerName: t(`${i18_PATH}A`),
			type: 'number',
			sortable: false,
			valueGetter: (params: GridValueGetterParams<undefined, IDettaglio>) => (params?.row?.tipo_lotto?.nome === "Lastre") ? params?.row?.lastreA : "-------",
			width: 60
		},
		{
			field: 'pezzi',
			headerName: t(`${i18_PATH}Pezzi`),
			type: 'number',
			sortable: false,
			renderCell: (params: GridRenderCellParams<string>) => <b>{params.formattedValue}</b>,
			width: 60
		},
		{
			field: 'misX',
			headerName: t(`${i18_PATH}Lun`),
			type: 'number',
			sortable: false,
			width: 80
		},
		{
			field: 'misY',
			headerName: t(`${i18_PATH}Alt`),
			type: 'number',
			sortable: false,
			width: 80
		},
		{
			field: 'misZ',
			headerName: t(`${i18_PATH}Spes`),
			type: 'number',
			sortable: false,
			width: 80
		},
		{
			field: 'quantitaUM',
			headerName: t(`${i18_PATH}Quantità`),
			type: 'number',
			sortable: false,
			renderCell: (params: GridRenderCellParams<number>) => {
				const value = parseFloat(String(params.value).replaceAll(',', '.'));
				return Number(value).toLocaleString("it-IT", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
			},
			width: 80
		},
		{
			field: 'um',
			headerName: '',
			sortable: false,
			valueGetter: (params: GridValueGetterParams<undefined, IDettaglio>) => (params?.value) ? t(String(params.value)) : "",
			width: 15
		},

		{
			field: 'costo',
			headerName: t(`${i18_PATH}Costo`),
			type: 'number',
			valueGetter: (params: GridValueGetterParams<undefined, IDettaglio>) => {
				const costiDiretti = params?.row?.costiDiretti || 0;
				const costiPadre = params?.row?.costiPadre || 0;
				const quantity = params?.row?.quantitaUM || 1;
				return (costiDiretti + costiPadre) / quantity;
			},
			renderCell: (params: GridRenderCellParams<number>) => {
				const value = parseFloat(String(params.value).replaceAll(',', '.'));
				return Number(value) ? Number(value).toLocaleString("it-IT", { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : "-------"
			},
			sortable: false,
			width: 120
		},
		{
			field: 'um_costo',
			headerName: '',
			sortable: false,
			valueGetter: (params: GridValueGetterParams<undefined, IDettaglio>) => (params?.row?.um) ? t(String(params.row.um)) : "",
			width: 15
		},
		{
			field: 'prezzo',
			headerName: t(`${i18_PATH}Prezzo`),
			type: 'number',
			sortable: false,
			renderCell: (params: GridRenderCellParams<number>) => {
				const value = parseFloat(String(params.value).replaceAll(',', '.'));
				return Number(value) ? Number(value).toLocaleString("it-IT", { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : "-------"
			},
			width: 120
		},
		{
			field: 'um_prezzo',
			headerName: '',
			sortable: false,
			valueGetter: (params: GridValueGetterParams<undefined, IDettaglio>) => (params?.row?.um) ? t(String(params.row.um)) : "",
			width: 15
		},
		{
			field: "stato_lavorazione",
			headerName: t(`${i18_PATH}Lavorazione`),
			valueGetter: (params: GridValueGetterParams<unknown, IDettaglio>) => t(params?.row?.stato_lavorazione?.nome ?? ''),
			sortable: false,
			minWidth: 120,
		},
		{
			field: 'disponibilita',
			headerName: t(`${i18_PATH}Disponibilita`),
			sortable: false,
			valueGetter: (params: GridValueGetterParams<undefined, IDettaglio>) => t(params?.row?.disponibilita?.nome ?? '')
		},
		{
			field: "opzCliente",
			headerName: t(`${i18_PATH}Cliente`),
			minWidth: 220,
			sortable: false,
		},
		{
			field: "agente",
			headerName: t(`${i18_PATH}Agente`),
			valueGetter: (params: GridValueGetterParams<unknown, IDettaglio>) => params?.row?.opzUser?.nome,
			minWidth: 220,
			sortable: false,
		},
		{
			field: "opzNote",
			headerName: t(`${i18_PATH}Note`),
			sortable: false,
			minWidth: 380,
		}
	]
)

export const createDefaultGridColumns = ({ t, canViewCommercials, canViewStoragePlace, canViewCosts, canViewCommercialOptions }: ICreateDefaultGridColumnsPros): GridColumns<IDettaglio> => {
	let columns = createAllColumns(t);
	if (!canViewCosts) columns = columns.filter((col => col.field !== 'costo' && col.field !== 'um_costo'));
	if (!canViewCommercials) columns = columns.filter((col => col.field !== 'prezzo' && col.field !== 'um_prezzo'));
	if (!canViewStoragePlace) columns = columns.filter((col => col.field !== 'magazzino' && col.field !== 'zona' && col.field !== 'fila'));
	if (!canViewCommercialOptions) columns = columns.filter((col => col.field !== 'opzStato' && col.field !== 'opzCliente' && col.field !== 'agente' && col.field !== 'opzNote'));
	return columns;
}
