import { Box, Card, CardActionArea, CardContent, CardMedia, Icon, Tooltip, Typography } from "@mui/material";
import { useState } from "react";
import { IDettaglio } from "../../../../../interfaces/dettaglio/models";
import { getJWT } from "../../../../../lib/jwt";
import { CellTipoLotto } from "../../../shared/tableCells";
import ImageDialog from "./dialogs/imageDialog";

const IMAGE_URL = `${process.env.REACT_APP_API_URL}image/`;

interface ILottoGridThumbnailProps {
	item: IDettaglio;
	onView: (id: number) => void;
}

const parseNumber = (value: number): string => {
	const _value = parseFloat(String(value).replaceAll(',', '.'));
	return Number(_value).toLocaleString("it-IT", { minimumFractionDigits: 2, maximumFractionDigits: 2 });

}

function GridThumbnail({ item, onView }: ILottoGridThumbnailProps) {
	const [isZoomOpen, setIsZoomOpen] = useState<boolean>(false);
	const jwt = getJWT();
	const cover = item.lotto?.cover ? `${IMAGE_URL}${item.lotto.cover.thumbPath}?token=${jwt})` : null;
	const openImage = () => {
		if (!!cover) setIsZoomOpen(true)
	}
	const closeImage = () => {
		setIsZoomOpen(false)
	}
	return (
		<Card>
			<CardActionArea onClick={openImage}>
				{!!cover && <CardMedia
					component="img"
					height="280"
					image={cover}
					alt="cover"
				/>
				}
				{!cover &&
					<Box sx={{ display: 'flex', justifyContent: "center", alignItems: "center", height: 280 }}>
						<Tooltip title="Nessuna Immagine">
							<Icon className="fa-light fa-image-slash" fontSize="large" color="action" sx={{ overflow: "visible" }} />
						</Tooltip>
					</Box>
				}
			</CardActionArea>
			<CardActionArea onClick={() => onView(item.idLotto as number)}>
				<CardContent>
					<Box sx={{ display: 'flex', alignItems: 'center', paddingBottom: 1 }}>
						<Box>
							<CellTipoLotto dettaglio={item} />
						</Box>
						<Typography gutterBottom variant="h5" component="span" sx={{ marginLeft: 1, marginBottom: 0 }}>
							{item.lotto?.codice} {item.suffissoCodice}
						</Typography>
					</Box>
					<Box sx={{ display: 'flex' }}>
						<Box sx={{ display: 'flex', alignItems: 'center', flex: 1 }}>
							<Typography gutterBottom variant="body2" component="span" sx={{ marginLeft: 1 }}>
								{item.lotto?.qualita?.nome}
							</Typography>
							<Typography gutterBottom variant="body2" component="span" sx={{ marginLeft: 1 }}>
								{parseNumber(item.quantitaUM)} {item.um}
							</Typography>
						</Box>
						<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', flex: 1 }}>
							<Typography gutterBottom variant="body2" component="span" sx={{ textTransform: "uppercase" }}>
								{item.magazzino?.nome}
							</Typography>
						</Box>
					</Box>
				</CardContent>
			</CardActionArea>
			<ImageDialog
				isOpen={isZoomOpen}
				cover={item.lotto?.cover || null}
				close={closeImage}
			/>
		</Card >
	);
}

export default GridThumbnail;