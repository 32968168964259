import { Box, Dialog, Icon, IconButton, Toolbar } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import React, { useEffect, useState } from 'react';
import Transition from '../../../../../../../lib/dialogTransition';
import { getJWT } from '../../../../../../../lib/jwt';
import { IImageZoomDialogProps } from './interfaces';

const IMAGE_URL = `${process.env.REACT_APP_API_URL}image/`;

function ZoomDialog({ image, isOpen, close }: IImageZoomDialogProps) {
	const [imageUrl, setImageUrl] = useState<string>()

	useEffect(() => {
		const jwt = getJWT();
		if (image) {
			const _imageUrl = `${IMAGE_URL}${image.hdPath}?token=${jwt})`;
			setImageUrl(_imageUrl)
		}
	}, [image])
	return (
		<Dialog
			fullScreen
			open={isOpen}
			onClose={close}
			TransitionComponent={Transition}
		>
			<AppBar position="absolute" color="transparent" sx={{ boxShadow: "none" }}>
				<Toolbar variant='dense'>
					<Box sx={{ flex: 1 }} />
					<IconButton
						edge="end"
						color="inherit"
						onClick={close}
						aria-label="close"
					>
						<Icon className="fa-light fa-xmark" sx={{ overflow: "visible" }} />
					</IconButton>
				</Toolbar>
			</AppBar>
			<Box sx={{ p: 1, boxSizing: "border-box", display: "flex", flexDirection: "column" }}>
				{!!image && !!imageUrl &&
					<img
						height="auto"
						src={imageUrl}
						alt={image.name}
						loading="lazy"
					/>
				}
			</Box>
		</Dialog>
	);
}

export default ZoomDialog;
