import { AxiosProgressEvent } from 'axios';
import React, { useEffect } from 'react';
import { errorHandler } from '../../error/handler';
import { removeImage, setCover, updateImage, uploadImage } from '../../fetch/image';
import { LottoImagesUploadFormData, LottoImageUpdateFormData, LottoImageUploadFormData } from '../../interfaces/lotto/forms';
import { useAppDispatch } from '../../store/hooks';
import { cleanUploadProgressAction, endActionToDoAction, messageAction, startActionToDoAction, startUploadProgressAction, updateUploadProgressAction } from '../../store/ui/actions';

export const useImageUpdate = (id: number | null, data: LottoImageUpdateFormData) => {
	const dispatch = useAppDispatch();
	React.useEffect(() => {
		const effect = async () => {
			if (!id || !data) return;
			const actionToDo = 'update-image';
			try {
				dispatch(startActionToDoAction({ actionToDo }));
				await updateImage(id, data);
				dispatch(endActionToDoAction({ actionToDo, success: true }));
				dispatch(messageAction({ message: "Immagine aggiornata", kind: "success" }));
			} catch (err) {
				errorHandler(err, actionToDo);
			}
		}
		effect();
	}, [dispatch, id, data]);
}

export const useImageSetCover = (id: number | null) => {
	const dispatch = useAppDispatch();
	React.useEffect(() => {
		const effect = async () => {
			if (!id) return;
			const actionToDo = 'set-cover-image';
			try {
				dispatch(startActionToDoAction({ actionToDo }));
				await setCover(id);
				dispatch(endActionToDoAction({ actionToDo, success: true }));
				dispatch(messageAction({ message: "Immagine di copertina modificata", kind: "success" }));
			} catch (err) {
				errorHandler(err, actionToDo);
			}
		}
		effect();
	}, [dispatch, id]);
}

export const useImageRemove = (id: number | null) => {
	const dispatch = useAppDispatch();
	React.useEffect(() => {
		const effect = async () => {
			if (!id) return;
			const actionToDo = 'remove-immagine';
			try {
				dispatch(startActionToDoAction({ actionToDo }));
				await removeImage(id);
				dispatch(endActionToDoAction({ actionToDo, success: true }));
				dispatch(messageAction({ message: "immagine rimossa", kind: "success" }));
			} catch (err) {
				errorHandler(err, actionToDo);
			}
		}
		effect();
	}, [dispatch, id]);
}

export const useImageUpload = (data: LottoImageUploadFormData) => {
	const dispatch = useAppDispatch();
	React.useEffect(() => {
		const onUploadProgress = (progress: AxiosProgressEvent) => {
			const { loaded, total } = progress;
			const progressPerc = loaded * 100 / (total || 1);
			dispatch(updateUploadProgressAction({ index: 0, progress: progressPerc }));
		}
		const effect = async () => {
			if (!data) return;
			const actionToDo = 'upload-immagine';
			try {
				dispatch(startActionToDoAction({ actionToDo }));
				dispatch(startUploadProgressAction({ numberOfFiles: 1 }));
				await uploadImage(data, onUploadProgress);
				dispatch(endActionToDoAction({ actionToDo, success: true }));
				dispatch(cleanUploadProgressAction({}));
				dispatch(messageAction({ message: "upload immagine riuscito", kind: "success" }));
			} catch (err) {
				errorHandler(err, actionToDo);
				dispatch(cleanUploadProgressAction({}));
			}
		}
		effect();
	}, [dispatch, data]);
}

export const useImagesUpload = (data: LottoImagesUploadFormData) => {
	const dispatch = useAppDispatch();
	useEffect(() => {
		const onUploadProgress = (progress: AxiosProgressEvent, index: number) => {
			const { loaded, total } = progress;
			const progressPerc = loaded * 100 / (total || 1);
			dispatch(updateUploadProgressAction({ index, progress: progressPerc }));
		}
		const effect = async () => {
			if (!data || !data.images.length) return;
			const actionToDo = 'upload-immagini';
			const { images, idLotto, isBlock } = data;
			const numberOfFiles = images.length;
			try {
				dispatch(startActionToDoAction({ actionToDo }));
				dispatch(startUploadProgressAction({ numberOfFiles }));
				await Promise.all(images.map(async (image, index) => {
					await uploadImage({ idLotto, image, isBlock }, (progress: AxiosProgressEvent) => onUploadProgress(progress, index));
				}))
				dispatch(endActionToDoAction({ actionToDo, success: true }));
				dispatch(cleanUploadProgressAction({}));
				dispatch(messageAction({ message: "upload immagini riuscito", kind: "success" }));
			} catch (err) {
				errorHandler(err, actionToDo);
				dispatch(cleanUploadProgressAction({}));
			}
		}
		effect();
	}, [dispatch, data]);
}