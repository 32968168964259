import { List, ListSubheader } from "@mui/material";
import CaveFilter from "./cave";
import { IMaterialeFiltersContainerProps } from "./interfaces";
import TipiLottoFilter from "./tipiLotto";

function MaterialeFiltersContainer({ filters, setFilters, canViewQuarry }: IMaterialeFiltersContainerProps) {
	return (
		<List
			dense={true}
			sx={{ p: 0 }}
			component="nav"
			subheader={
				<ListSubheader component="div">
					Materiale
				</ListSubheader>
			}
		>
			<TipiLottoFilter
				filters={filters}
				setFilters={setFilters}
			/>
			{canViewQuarry &&
				<CaveFilter
					filters={filters}
					setFilters={setFilters}
				/>
			}
		</List>
	)
}

export default MaterialeFiltersContainer;