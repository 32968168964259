import { Collapse, Icon, List, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { useEffect, useState } from "react";
import { EOptionType } from "../../../../../../../interfaces/enums";
import { IMagazzino } from "../../../../../../../interfaces/place/models";
import { isFilterAndValueSelected } from "../../../../../../../lib/filters";
import { useAppSelector } from "../../../../../../../store/hooks";
import { IFilterProps } from "../../interfaces";

function MagazziniFilter({ filters, setFilters }: IFilterProps) {

	const [open, setOpen] = useState<boolean>(false);
	const [list, setList] = useState<IMagazzino[]>([])

	const magazzinoList = useAppSelector((state) => state.place_magazzino.list);
	const magazzinoItems = useAppSelector((state) => state.place_magazzino.items);

	const toggle = () => {
		setOpen(!open);
	}

	const toggleSelected = (item: IMagazzino) => {
		if (isFilterAndValueSelected(filters, EOptionType.magazzino, item.id)) {
			setFilters(filters.filter(opt => (opt.type !== EOptionType.magazzino && opt.type !== EOptionType.zona && opt.type !== EOptionType.fila)));
		} else {
			setFilters([
				...filters.filter(opt => (opt.type !== EOptionType.magazzino && opt.type !== EOptionType.zona && opt.type !== EOptionType.fila)),
				{
					type: EOptionType.magazzino,
					numericValue: item.id,
					stringValue: item.nome
				}
			]);
		}
	}

	useEffect(() => {
		const magazzini: IMagazzino[] = (!!magazzinoList && !!magazzinoItems) ? magazzinoList.map(id => magazzinoItems[id]) : [];
		setList(magazzini);
	}, [magazzinoList, magazzinoItems]);

	return (
		<>
			<ListItemButton onClick={toggle}>
				<ListItemIcon>
					<Icon className="fa-light fa-warehouse-full" sx={{ overflow: "visible" }} />
				</ListItemIcon>
				<ListItemText primary="Magazzini" />
				{open ? <Icon className="fa-light fa-angle-up" fontSize="small" sx={{ overflow: "visible" }} /> : <Icon className="fa-light fa-angle-down" fontSize="small" sx={{ overflow: "visible" }} />}
			</ListItemButton>
			<Collapse in={open} timeout="auto" unmountOnExit>
				<List dense={true}>
					{list.map(item => (
						<ListItemButton key={item.id} sx={{ display: "flex", pl: 5, pr: 1 }} onClick={() => toggleSelected(item)} selected={isFilterAndValueSelected(filters, EOptionType.magazzino, item.id)}>
							<ListItemText sx={{ flex: 1 }} primary={item.nome} />
							<ListItemIcon sx={{ minWidth: 0 }}>
								{isFilterAndValueSelected(filters, EOptionType.magazzino, item.id) && <Icon className="fa-light fa-check" sx={{ overflow: "visible" }} />}
							</ListItemIcon>
						</ListItemButton>
					))}
				</List>
			</Collapse>
		</>
	)
}

export default MagazziniFilter;