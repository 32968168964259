import { AppBar, Box, Button, Icon, IconButton, Toolbar, Tooltip, Typography } from '@mui/material';
import { LinkProps, NavLink, useMatch, useResolvedPath } from 'react-router-dom';
import logo from "../../../assets/logo.png";
import { EClientsViews, EProspectsViews } from '../../../interfaces/user/enums';
import { IUser } from '../../../interfaces/user/models';

interface INavigationBarProps {
	user: IUser;
	onLogout: () => void;
}

function CustomLink({ children, to, ...props }: LinkProps) {
	let resolved = useResolvedPath(to);
	let match = useMatch({ path: resolved.pathname, end: false });

	return (
		<Button
			color="inherit"
			sx={{ mx: 1, my: 1, display: 'flex' }}
			component={NavLink}
			variant={match ? "outlined" : "text"}
			to={to}
		>
			{children}
		</Button>
	);
}

function NavigationBar({ user, onLogout }: INavigationBarProps) {
	return (
		<AppBar position="static" sx={{ zIndex: 999 }}>
			<Toolbar>
				<NavLink to="/"><img src={logo} alt="logo" height={60} /></NavLink>
				{!user.unknown &&
					<Box sx={{ display: 'flex', flexGrow: 1, px: 8 }}>
						<CustomLink to="/prodotti">Prodotti</CustomLink>
						{(user.admin || user.user_group?.group_permissions?.prospects !== EProspectsViews.none) && <CustomLink to="/prospects">Prospects</CustomLink>}
						{(user.admin || user.user_group?.group_permissions?.clients !== EClientsViews.none) && <CustomLink to="/clienti">Clienti</CustomLink>}
						{(user.admin || user.user_group?.group_permissions?.canViewUsers) && <CustomLink to="/utenti">Utenti</CustomLink>}
						{(user.admin) && <CustomLink to="/gruppi-utente">Gruppi Utenti</CustomLink>}
					</Box>
				}
				{!user.unknown &&
					<Box sx={{ display: 'flex', flexGrow: 1, justifyContent: 'flex-end' }}>
						<Button color="inherit" sx={{ mx: 1, my: 1, display: 'flex' }} startIcon={<Icon className="fa-light fa-circle-user" />}>
							<Typography
								variant="caption"
								noWrap
								component="div"
								sx={{ mx: 1 }}
							>
								{user?.nome}
							</Typography>
						</Button>
						<Tooltip title="Esci">
							<IconButton
								size="large"
								color="inherit"
								onClick={onLogout}
							>
								<Icon className="fa-light fa-right-from-bracket" sx={{ overflow: "visible" }} />
							</IconButton>
						</Tooltip>
					</Box>
				}
			</Toolbar>
		</AppBar>
	)

}

export default NavigationBar;