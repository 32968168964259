import { Collapse, Icon, List, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { useEffect, useState } from "react";
import { EOptionType } from "../../../../../../../interfaces/enums";
import { IQualita } from "../../../../../../../interfaces/lotto/attributes/models";
import { isFilterAndValueSelected } from "../../../../../../../lib/filters";
import { useAppSelector } from "../../../../../../../store/hooks";
import { IFilterProps } from "../../interfaces";

function QualitaFilter({ filters, setFilters }: IFilterProps) {

	const [open, setOpen] = useState<boolean>(false);
	const [list, setList] = useState<IQualita[]>([])

	const qualitaList = useAppSelector((state) => state.lotto_qualita.list);
	const qualitaItems = useAppSelector((state) => state.lotto_qualita.items);

	const toggle = () => {
		setOpen(!open);
	}

	const toggleSelected = (item: IQualita) => {
		if (isFilterAndValueSelected(filters, EOptionType.qualita, item.id)) {
			setFilters(filters.filter(opt => !(opt.type === EOptionType.qualita && opt.numericValue === item.id)));
		} else {
			setFilters([...filters, {
				type: EOptionType.qualita,
				numericValue: item.id,
				stringValue: item.nome
			}]);
		}
	}

	useEffect(() => {
		const qualita: IQualita[] = (!!qualitaList && !!qualitaItems) ? qualitaList.map(id => qualitaItems[id]) : [];
		setList(qualita);
	}, [qualitaList, qualitaItems]);

	return (
		<>
			<ListItemButton onClick={toggle}>
				<ListItemIcon>
					<Icon className="fa-light fa-circle-bookmark" sx={{ overflow: "visible" }} />
				</ListItemIcon>
				<ListItemText primary="Qualità" />
				{open ? <Icon className="fa-light fa-angle-up" fontSize="small" sx={{ overflow: "visible" }} /> : <Icon className="fa-light fa-angle-down" fontSize="small" sx={{ overflow: "visible" }} />}
			</ListItemButton>
			<Collapse in={open} timeout="auto" unmountOnExit>
				<List dense={true}>
					{list.map(item => (
						<ListItemButton key={item.id} sx={{ display: "flex", pl: 5, pr: 1 }} onClick={() => toggleSelected(item)} selected={isFilterAndValueSelected(filters, EOptionType.qualita, item.id)}>
							<ListItemText sx={{ flex: 1 }} primary={item.nome} />
							<ListItemIcon sx={{ minWidth: 0 }}>
								{isFilterAndValueSelected(filters, EOptionType.qualita, item.id) && <Icon className="fa-light fa-check" sx={{ overflow: "visible" }} />}
							</ListItemIcon>
						</ListItemButton>
					))}
				</List>
			</Collapse>
		</>
	)
}

export default QualitaFilter;