import { IBaseError } from "../../interfaces/errors";
import { IDeleteResponse, IListResponse, IStoreResponse, IUpdateResponse } from "../../interfaces/rest";
import { IUserGroup, IUserGroupStore, IUserGroupUpdate } from "../../interfaces/user/models";
import { getJWT } from "../../lib/jwt";


const BASE_URL = `${process.env.REACT_APP_API_URL}user-group`

export const getUserGroup = async (id: number): Promise<IUserGroup> => {
	const url = `${BASE_URL}/${id}`;
	const headers = new Headers();
	headers.append('Content-Type', 'application/json');
	const jwt = getJWT();
	if (jwt) headers.append('Authorization', 'Bearer ' + jwt);
	const response = await fetch(url, {
		method: "GET",
		headers: headers
	});
	const json: IUserGroup | IBaseError = await response.json();
	if (response.status !== 200) throw json;
	return json as IUserGroup;
}

export const getUserGroupList = async (): Promise<IListResponse<IUserGroup>> => {
	const url = `${BASE_URL}/list`;
	const headers = new Headers();
	headers.append('Content-Type', 'application/json');
	const jwt = getJWT();
	if (jwt) headers.append('Authorization', 'Bearer ' + jwt);
	const response = await fetch(url, {
		method: "GET",
		headers: headers
	});
	const json: IListResponse<IUserGroup> | IBaseError = await response.json();
	if (response.status !== 200) throw json;
	return json as IListResponse<IUserGroup>;
}

export const storeUserGroup = async (data: IUserGroupStore): Promise<IStoreResponse> => {
	const url = `${BASE_URL}`;
	const headers = new Headers();
	headers.append('Content-Type', 'application/json');
	const jwt = getJWT();
	if (jwt) headers.append('Authorization', 'Bearer ' + jwt);
	const body = JSON.stringify(data);
	const response = await fetch(url, {
		method: "POST",
		headers: headers,
		body
	});
	const json: IStoreResponse | IBaseError = await response.json();
	if (response.status !== 200) throw json;
	return json as IStoreResponse;
}

export const updateUserGroup = async (id: number, data: IUserGroupUpdate): Promise<IUpdateResponse> => {
	const url = `${BASE_URL}/${id}`;
	const headers = new Headers();
	headers.append('Content-Type', 'application/json');
	const jwt = getJWT();
	if (jwt) headers.append('Authorization', 'Bearer ' + jwt);
	const body = JSON.stringify(data);
	const response = await fetch(url, {
		method: "PUT",
		headers: headers,
		body
	});
	const json: IUpdateResponse | IBaseError = await response.json();
	if (response.status !== 200) throw json;
	return json as IUpdateResponse;
}

export const removeUserGroup = async (id: number): Promise<IDeleteResponse> => {
	const url = `${BASE_URL}/${id}`;
	const headers = new Headers();
	headers.append('Content-Type', 'application/json');
	const jwt = getJWT();
	if (jwt) headers.append('Authorization', 'Bearer ' + jwt);
	const response = await fetch(url, {
		method: "DELETE",
		headers: headers
	});
	const json: IDeleteResponse | IBaseError = await response.json();
	if (response.status !== 200) throw json;
	return json as IDeleteResponse;
}