import { AppBar, Box, Button, Checkbox, Dialog, DialogActions, FormControl, FormControlLabel, FormGroup, FormHelperText, Icon, IconButton, InputLabel, MenuItem, Select, SelectChangeEvent, TextField, Toolbar, Typography } from '@mui/material';
import { ChangeEvent, FocusEventHandler, useEffect, useState } from 'react';
import { useLottoClearShare, useLottoShare } from '../../../../../../../effects/lotto/lotto';
import { LottoShareFormData } from '../../../../../../../interfaces/lotto/forms';
import Transition from '../../../../../../../lib/dialogTransition';
import { useAppSelector } from '../../../../../../../store/hooks';
import { IShareDialogProps } from '../interfaces';

const DEFAULT_EXPIRES_TIME = 60 * 60 * 24 * 30;

const EXPIRES = [
	{
		time: 60 * 60 * 24 * 7,
		name: "7 giorni"
	},
	{
		time: 60 * 60 * 24 * 15,
		name: "15 giorni"
	},
	{
		time: 60 * 60 * 24 * 30,
		name: "30 giorni"
	}
]

function ShareDialog({ lotto, dettagli, isOpen, close }: IShareDialogProps) {
	const [isLinkCopied, setIsLinkCopied] = useState<boolean>(false);
	const [clear, setClear] = useState<number>(0);
	const [id, setId] = useState<number | null>(null);
	const [expiresIn, setExpiresIn] = useState<string>("");
	const [expiresInError, setExpiresInError] = useState<string | null>(null);
	const [showPrice, setShowPrice] = useState<boolean>(false);
	const [shareData, setShareData] = useState<LottoShareFormData>(null);
	const [link, setLink] = useState<string>("");
	const token = useAppSelector((state) => state.lotto.shareToken);

	const onExpiresInChange = (event: SelectChangeEvent<string>) => {
		setExpiresIn(event.target.value);
	};
	const onShowPriceChange = (event: ChangeEvent<HTMLInputElement>) => {
		setShowPrice(event.target.checked);
	}

	const checkError = (): boolean => {
		let errors = 0;
		if (!expiresIn) {
			setExpiresInError('Campo obbligatorio');
			errors++;
		} else setExpiresInError(null);

		return errors > 0;
	}

	const onSubmit = () => {
		if (checkError()) return;
		if (!id) return;
		const bundles = Array.from(new Set(dettagli.map(dettaglio => dettaglio.bundle).filter(Boolean)));
		const _updateData: LottoShareFormData = {
			idLotto: id,
			bundles,
			showPrice,
			expiresIn: (!!expiresIn) ? parseInt(expiresIn) : DEFAULT_EXPIRES_TIME
		}
		setShareData(_updateData);
	}

	const copyLinkToClipboard = async () => {
		if (!link) return;
		if ('clipboard' in navigator) {
			return await navigator.clipboard.writeText(link);
		} else {
			return document.execCommand('copy', true, link);
		}
	}

	const onHandleLinkFocus: FocusEventHandler = (event) => {
		(event?.target as HTMLInputElement)?.select();
		copyLinkToClipboard().then(() => {
			setIsLinkCopied(true);
			setTimeout(() => {
				setIsLinkCopied(false)
			}, 2000);
		}).catch(err => {
			console.error(err);
		})
	}

	const onClose = () => {
		const _clear = clear + 1;
		setClear(_clear);
		close();
	}

	useEffect(() => {
		setId(null);
		setExpiresIn("");
		setExpiresInError(null);
		setShareData(null);
		if (!!lotto) {
			setId(lotto.id);
			setClear(0);
		}
	}, [lotto, isOpen]);
	useEffect(() => {
		if (!!token) {
			const _link = `${window.location.origin}/?token=${token}&cod=${lotto?.codice}`;
			setLink(_link);
		} else {
			setLink("");
		}
	}, [token, lotto]);
	useLottoClearShare(clear);
	useLottoShare(shareData);
	return (
		<Dialog
			open={isOpen}
			onClose={onClose}
			TransitionComponent={Transition}
			fullWidth
			maxWidth="md"
		>
			<AppBar sx={{ position: "relative" }}>
				<Toolbar variant="dense">
					<Typography variant="h5" sx={{ flex: 1 }} display="block" >Genera un link per condividere gli elementi selezionati</Typography>
					<IconButton
						edge="end"
						color="inherit"
						onClick={close}
						aria-label="close"
					>
						<Icon className="fa-light fa-xmark" sx={{ overflow: "visible" }} />
					</IconButton>
				</Toolbar>

			</AppBar>
			<Box sx={{ p: 2, overflowY: 'auto' }}>
				<FormControl
					error={!!expiresInError}
					fullWidth sx={{ mt: 1, mb: .5 }}
				>
					<InputLabel id="expiresIn-label">Tempo di validità</InputLabel>
					<Select
						labelId="expiresIn-label"
						value={expiresIn}
						label="Tempo di validità"
						onChange={onExpiresInChange}
					>
						{EXPIRES.map(ex => (
							<MenuItem
								key={ex.time}
								value={ex.time}
							>
								{ex.name}
							</MenuItem>
						))}
					</Select>
					{!!expiresInError &&
						<FormHelperText >
							{expiresInError}
						</FormHelperText>
					}
				</FormControl>
				<FormGroup>
					<FormControlLabel
						control={
							<Checkbox
								checked={showPrice}
								onChange={onShowPriceChange}
								inputProps={{ 'aria-label': 'Mostra il prezzo' }}
							/>
						}
						label="Mostrare il prezzo?"
					/>
				</FormGroup>

				<TextField
					margin="dense"
					fullWidth
					label="LINK"
					type="text"
					name="lotto_share_link"
					InputProps={{
						readOnly: true,
					}}
					helperText={isLinkCopied ? "Link copiato" : " "}
					onFocus={onHandleLinkFocus}
					value={link}
				/>
			</Box>
			<DialogActions>
				<Button onClick={onClose}>Chiudi</Button>
				<Button onClick={onSubmit} variant="contained" color="primary">Genera Link</Button>
			</DialogActions>
		</Dialog>
	);
}

export default ShareDialog;