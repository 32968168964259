import { createAction } from '@reduxjs/toolkit';
import { IDettaglioUpdateCommercialsStore, IDettaglioUpdateNotesStore, IDettaglioUpdateStatoLavorazioneStore, IDettaglioUpdateStorageStore } from '../../../interfaces/dettaglio/models';
import { ILotto } from '../../../interfaces/lotto/models';
import { CLEAR_SHARE_TOKEN, EMPTY, GET, GET_SHARE_TOKEN, ILottoState, LIST, UPDATE_COMMERCIALS, UPDATE_NOTES, UPDATE_STATO_LAVORAZIONE, UPDATE_STORAGE } from './types';
export const listAction = createAction<ILottoState>(LIST);

export const getAction = createAction<ILotto>(GET);

export const emptyAction = createAction(EMPTY);

export const getShareTokenAction = createAction<string>(GET_SHARE_TOKEN);

export const clearShareTokenAction = createAction(CLEAR_SHARE_TOKEN);

export const updateCommercialsAction = createAction<IDettaglioUpdateCommercialsStore>(UPDATE_COMMERCIALS);

export const updateStorageAction = createAction<IDettaglioUpdateStorageStore>(UPDATE_STORAGE);

export const updateStatoLavorazioneAction = createAction<IDettaglioUpdateStatoLavorazioneStore>(UPDATE_STATO_LAVORAZIONE);

export const updateNotesAction = createAction<IDettaglioUpdateNotesStore>(UPDATE_NOTES);
