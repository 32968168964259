import { IBaseError } from "../../interfaces/errors";
import { IZona } from "../../interfaces/place/models";
import { IListResponse } from "../../interfaces/rest";
import { getJWT } from "../../lib/jwt";


const BASE_URL = `${process.env.REACT_APP_API_URL}zona`

export const getZona = async (id: number): Promise<IZona> => {
	const url = `${BASE_URL}/${id}`;
	const headers = new Headers();
	headers.append('Content-Type', 'application/json');
	const jwt = getJWT();
	if (jwt) headers.append('Authorization', 'Bearer ' + jwt);
	const response = await fetch(url, {
		method: "GET",
		headers: headers
	});
	const json: IZona | IBaseError = await response.json();
	if (response.status !== 200) throw json;
	return json as IZona;
}

export const getZonaList = async (): Promise<IListResponse<IZona>> => {
	const url = `${BASE_URL}/list`;
	const headers = new Headers();
	headers.append('Content-Type', 'application/json');
	const jwt = getJWT();
	if (jwt) headers.append('Authorization', 'Bearer ' + jwt);
	const response = await fetch(url, {
		method: "GET",
		headers: headers
	});
	const json: IListResponse<IZona> | IBaseError = await response.json();
	if (response.status !== 200) throw json;
	return json as IListResponse<IZona>
}