import { Box, Button, Icon, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getElementsNumber, getMQPrice, getTotalCosts, getTotalMQ, getTotalNumberOfSlabs, getTotalSlabsPrice, isAllSlabs } from "../../../../../../lib/functions";
import { IGridFooterProps } from "./interfaces";

const i18_PATH = 'prodotto.view.lottoDettagli.grids.gridFooter.'

function GridFooter({
	selectedItems,
	canShare,
	canSetCommercials,
	canSetStoragePlace,
	canSetFeatures,
	canSetCommercialOptions,
	canViewCommercials,
	canViewCosts,
	showAdminColumns,
	onUpdate,
	onShare,
	onSplit,
	onCommercialOptionsUpdate,
	onPackingList
}: IGridFooterProps) {
	const { t } = useTranslation();
	const [rowsNumber, setRowsNumber] = useState<number>(0);
	const [onlySlabs, setOnlySlabs] = useState<boolean>(false);
	const [slabs, setSlabs] = useState<number>(0);
	const [MQ, setMQ] = useState<string>('0,00');
	const [MQPrice, setMQPrice] = useState<string>('0,00');
	const [totalPrice, setTotalPrice] = useState<string>('0,00');
	const [totalCosts, setTotalCosts] = useState<string>('0,00');

	useEffect(() => {
		setRowsNumber(getElementsNumber(selectedItems));
		setOnlySlabs(isAllSlabs(selectedItems));
		setSlabs(getTotalNumberOfSlabs(selectedItems));
		setMQ(getTotalMQ(selectedItems));
		setMQPrice(getMQPrice(selectedItems));
		setTotalPrice(getTotalSlabsPrice(selectedItems));
		setTotalCosts(getTotalCosts(selectedItems));
	}, [selectedItems])
	return (
		<Box sx={{ height: 52, px: 2 }}>
			<Box sx={{ display: 'flex', alignItems: 'center' }}>
				<Box sx={{ display: "flex", flex: 1 }}>
					{ /*<Box sx={{ p: 1, display: "flex", alignItems: "baseline" }}>
						<Typography variant="overline" color="text.secondary" sx={{ mr: 1 }}>Righe:</Typography>
						<Typography variant="body1" component="span" color="text.primary">{rowsNumber}</Typography>
						</Box> */
					}
					{onlySlabs &&
						<>
							<Box sx={{ p: 1, display: "flex", alignItems: "baseline" }}>
								<Typography variant="overline" color="text.secondary" sx={{ mr: 1 }}>{t(`${i18_PATH}Pezzi`)}:</Typography>
								<Typography variant="body1" component="span" color="text.primary">{slabs}</Typography>
							</Box>
							<Box sx={{ p: 1, display: "flex", alignItems: "baseline" }}>
								<Typography variant="overline" color="text.secondary" sx={{ mr: 1 }}>{t('MQ')}:</Typography>
								<Typography variant="body1" component="span" color="text.primary">{MQ}</Typography>
							</Box>
							{canViewCommercials &&
								<>
									<Box sx={{ p: 1, display: "flex", alignItems: "baseline" }}>
										<Typography variant="overline" color="text.secondary" sx={{ mr: 1 }}>{t(`${i18_PATH}Prezzo al MQ`)}:</Typography>
										<Typography variant="body1" component="span" color="text.primary">{MQPrice} €</Typography>
									</Box>
									<Box sx={{ p: 1, display: "flex", alignItems: "baseline" }}>
										<Typography variant="overline" color="text.secondary" sx={{ mr: 1 }}>{t(`${i18_PATH}Totale`)}:</Typography>
										<Typography variant="body1" component="span" color="text.primary">{totalPrice} €</Typography>
									</Box>
								</>
							}
							{(canViewCosts && showAdminColumns) &&
								<Box sx={{ p: 1, display: "flex", alignItems: "baseline" }}>
									<Typography variant="overline" color="text.secondary" sx={{ mr: 1 }}>{t(`${i18_PATH}Costo totale`)}:</Typography>
									<Typography variant="body1" component="span" color="text.primary">{totalCosts} €</Typography>
								</Box>
							}
						</>
					}
				</Box>
				<Box sx={{ display: "flex", flex: 1, justifyContent: 'flex-end' }}>
					{(rowsNumber > 0 && canShare) &&
						< Box sx={{ pl: .5 }}>
							<Button onClick={onShare} variant="outlined" startIcon={<Icon className="fa-light fa-share-from-square" fontSize="small" sx={{ overflow: "visible" }} />}>
								{t(`${i18_PATH}Condividi`)}
							</Button>
						</Box>

					}
					{(rowsNumber > 0 && canSetCommercialOptions) &&
						<Box sx={{ pl: .5 }}>
							<Button onClick={onCommercialOptionsUpdate} variant="outlined" startIcon={<Icon className="fa-light fa-circle-bookmark" fontSize="small" sx={{ overflow: "visible" }} />}>
								{t(`${i18_PATH}Opziona`)}
							</Button>
						</Box>
					}
					{(rowsNumber > 0 && onlySlabs) &&
						<Box sx={{ pl: .5 }}>
							<Button onClick={onPackingList} variant="outlined" startIcon={<Icon className="fa-light fa-boxes-packing" fontSize="small" sx={{ overflow: "visible" }} />}>
								{t(`${i18_PATH}Packing List`)}
							</Button>
						</Box>
					}
					{(rowsNumber === 1 && onlySlabs && canSetCommercialOptions) &&
						< Box sx={{ pl: .5 }}>
							<Button onClick={onSplit} variant="outlined" startIcon={<Icon className="fa-light fa-split" fontSize="small" sx={{ overflow: "visible" }} />}>
								{t(`${i18_PATH}Dividi`)}
							</Button>
						</Box>
					}
					{(rowsNumber > 0 && (canSetCommercials || canSetStoragePlace || canSetFeatures)) &&
						<Box sx={{ pl: .5 }}>
							<Button onClick={onUpdate} variant="outlined" startIcon={<Icon className="fa-light fa-pen-circle" fontSize="small" sx={{ overflow: "visible" }} />}>
								{t(`${i18_PATH}Modifica`)}
							</Button>
						</Box>
					}
				</Box>
			</Box>
		</Box >
	)
}

export default GridFooter;