import * as React from 'react';
import { errorHandler } from '../../error/handler';
import { getDettaglio, getDettaglioList, splitDettaglio, updateCommercialOptionsDettaglio, updateCommercialsDettaglio, updateNotestDettaglio, updateStatoLavorazioneDettaglio, updateStorageDettaglio } from '../../fetch/dettaglio/dettaglio';
import { DettaglioSplitFormData, DettaglioUpdateCommercialOptionsFormData, DettaglioUpdateCommercialsFormData, DettaglioUpdateNotesFormData, DettaglioUpdateStatoLavorazioneFormData, DettaglioUpdateStorageFormData } from '../../interfaces/dettaglio/forms';
import { IDettaglio } from '../../interfaces/dettaglio/models';
import { calcQualtityUM } from '../../lib/functions';
import { dettaglioGrouping } from '../../lib/grouping';
import { normalizerTransform } from '../../lib/normalizer';
import { dettaglioTransform } from '../../lib/transform';
import { emptyAction, getAction, listAction } from '../../store/dettaglio/dettaglio/actions';
import { IDettaglioState } from '../../store/dettaglio/dettaglio/types';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { updateCommercialsAction, updateNotesAction, updateStatoLavorazioneAction, updateStorageAction } from '../../store/lotto/lotto/actions';
import { endActionToDoAction, messageAction, startActionToDoAction } from '../../store/ui/actions';

export const useDettaglioGet = (id: number | null, query: number) => {
	const dispatch = useAppDispatch();
	React.useEffect(() => {
		const effect = async () => {
			if (!(!!id && query)) return;
			const actionToDo = 'get-dettaglio';
			try {
				dispatch(startActionToDoAction({ actionToDo }));
				const dettaglioLotto = await getDettaglio(id);
				dispatch(getAction(dettaglioLotto));
				dispatch(endActionToDoAction({ actionToDo, success: true }));
			} catch (err) {
				errorHandler(err, actionToDo);
			}
		}
		effect();
	}, [dispatch, id, query])
}

export const useDettaglioList = (query: number) => {
	const dispatch = useAppDispatch();
	const lottoMap = useAppSelector((state) => state.lotto.items);
	const tipoLottoMap = useAppSelector((state) => state.dettaglio_tipoLotto.items);
	const magazzinoMap = useAppSelector((state) => state.place_magazzino.items);
	const zonaMap = useAppSelector((state) => state.place_zona.items);
	const filaMap = useAppSelector((state) => state.place_fila.items);
	const disponibilitaMap = useAppSelector((state) => state.dettaglio_disponibilita.items);
	const statoLavorazioneMap = useAppSelector((state) => state.dettaglio_disponibilita.items);
	React.useEffect(() => {
		const effect = async () => {
			if (!(!!query && !!lottoMap && !!tipoLottoMap && !!magazzinoMap && !!zonaMap && !!filaMap && !!disponibilitaMap && !!statoLavorazioneMap)) return;
			const actionToDo = 'list-dettaglio';
			try {
				dispatch(startActionToDoAction({ actionToDo }));
				const { results, items: _items } = await getDettaglioList();
				const groupedItems = dettaglioGrouping(_items.map(item => {
					const quantitaUM = calcQualtityUM(item);
					return {
						...item,
						quantitaUM
					}
				}));
				const { list, items } = normalizerTransform<number, IDettaglio, IDettaglio>(groupedItems, (item) => dettaglioTransform(item, lottoMap, tipoLottoMap, magazzinoMap, zonaMap, filaMap, disponibilitaMap, statoLavorazioneMap));
				const actionPayload: IDettaglioState = {
					results,
					list,
					items
				}
				dispatch(listAction(actionPayload));
				dispatch(endActionToDoAction({ actionToDo, success: true }));
			} catch (err) {
				errorHandler(err, actionToDo);
			}
			return () => {
				dispatch(emptyAction());
			}
		}
		effect();
	}, [dispatch, query, lottoMap, tipoLottoMap, magazzinoMap, zonaMap, filaMap, disponibilitaMap, statoLavorazioneMap])
}

export const useDettaglioSplit = (id: number | null, data: DettaglioSplitFormData) => {
	const dispatch = useAppDispatch();
	React.useEffect(() => {
		const effect = async () => {
			if (!id || !data) return;
			const actionToDo = 'split-dettaglio';
			try {
				dispatch(startActionToDoAction({ actionToDo }));
				await splitDettaglio(id, data);
				dispatch(endActionToDoAction({ actionToDo, success: true }));
				dispatch(messageAction({ message: "Dettaglio diviso", kind: "success" }));
			} catch (err) {
				errorHandler(err, actionToDo);
			}
		}
		effect();
	}, [dispatch, id, data]);
}

export const useDettaglioUpdateStorage = (lottoId: number, dettaglioIds: number[], data: DettaglioUpdateStorageFormData) => {
	const dispatch = useAppDispatch();
	React.useEffect(() => {
		const effect = async () => {
			if (!dettaglioIds.length || !data) return;
			const actionToDo = 'update-storage-dettaglio';
			try {
				dispatch(startActionToDoAction({ actionToDo }));
				for (let dettaglioId of dettaglioIds) {
					await updateStorageDettaglio(dettaglioId, data);
				}
				dispatch(endActionToDoAction({ actionToDo, success: true }));
				dispatch(messageAction({ message: "Informazioni di magazzino aggiornate", kind: "success" }));
				dispatch(updateStorageAction({ lottoId, dettaglioIds, ...data }));
			} catch (err) {
				errorHandler(err, actionToDo);
			}
		}
		effect();
	}, [dispatch, lottoId, dettaglioIds, data]);
}

export const useDettaglioUpdateCommercials = (lottoId: number, dettaglioIds: number[], data: DettaglioUpdateCommercialsFormData) => {
	const dispatch = useAppDispatch();
	React.useEffect(() => {
		const effect = async () => {
			if (!dettaglioIds.length || !data) return;
			const actionToDo = 'update-commercials-dettaglio';
			try {
				dispatch(startActionToDoAction({ actionToDo }));
				for (let dettaglioId of dettaglioIds) {
					await updateCommercialsDettaglio(dettaglioId, data);
				}
				dispatch(endActionToDoAction({ actionToDo, success: true }));
				dispatch(messageAction({ message: "Informazioni commerciali aggiornate", kind: "success" }));
				dispatch(updateCommercialsAction({ lottoId, dettaglioIds, ...data }));
			} catch (err) {
				errorHandler(err, actionToDo);
			}
		}
		effect();
	}, [dispatch, lottoId, dettaglioIds, data]);
}

export const useDettaglioUpdateStatoLavorazione = (lottoId: number, dettaglioIds: number[], data: DettaglioUpdateStatoLavorazioneFormData) => {

	const dispatch = useAppDispatch();
	React.useEffect(() => {
		const effect = async () => {
			if (!dettaglioIds.length || !data) return;
			const actionToDo = 'update-stato-lavorazione-dettaglio';
			try {
				dispatch(startActionToDoAction({ actionToDo }));
				for (let dettaglioId of dettaglioIds) {
					await updateStatoLavorazioneDettaglio(dettaglioId, data);
				}
				dispatch(endActionToDoAction({ actionToDo, success: true }));
				dispatch(messageAction({ message: "Stato lavorazione aggiornato", kind: "success" }));
				dispatch(updateStatoLavorazioneAction({ lottoId, dettaglioIds, ...data }));
			} catch (err) {
				errorHandler(err, actionToDo);
			}
		}
		effect();
	}, [dispatch, lottoId, dettaglioIds, data]);
}

export const useDettaglioUpdateCommercialOptions = (lottoId: number, dettaglioIds: number[], data: DettaglioUpdateCommercialOptionsFormData) => {

	const dispatch = useAppDispatch();
	React.useEffect(() => {
		const effect = async () => {
			if (!dettaglioIds.length || !data) return;
			const actionToDo = 'update-commercial-options-dettaglio';
			try {
				dispatch(startActionToDoAction({ actionToDo }));
				for (let dettaglioId of dettaglioIds) {
					await updateCommercialOptionsDettaglio(dettaglioId, data);
				}
				dispatch(endActionToDoAction({ actionToDo, success: true }));
				dispatch(messageAction({ message: "Informazioni commerciali aggiornate", kind: "success" }));
			} catch (err) {
				errorHandler(err, actionToDo);
			}
		}
		effect();
	}, [dispatch, lottoId, dettaglioIds, data]);
}

export const useDettaglioUpdateNotes = (lottoId: number, dettaglioIds: number[], data: DettaglioUpdateNotesFormData) => {

	const dispatch = useAppDispatch();
	React.useEffect(() => {
		const effect = async () => {
			if (!dettaglioIds.length || !data) return;
			const actionToDo = 'update-notes-dettaglio';
			try {
				dispatch(startActionToDoAction({ actionToDo }));
				for (let dettaglioId of dettaglioIds) {
					await updateNotestDettaglio(dettaglioId, data);
				}
				dispatch(endActionToDoAction({ actionToDo, success: true }));
				dispatch(messageAction({ message: "Note aggiornate", kind: "success" }));
				dispatch(updateNotesAction({ lottoId, dettaglioIds, ...data }));
			} catch (err) {
				errorHandler(err, actionToDo);
			}
		}
		effect();
	}, [dispatch, lottoId, dettaglioIds, data]);
}
