import { Box, Button, Container, Icon } from "@mui/material";
import { ReactNode } from "react";
import { LinkProps, Navigate, NavLink } from "react-router-dom";
import logo from '../../assets/logo.png';
import { EClientsViews, EProspectsViews } from "../../interfaces/user/enums";
import { useAppSelector } from "../../store/hooks";

interface ICustomProp extends LinkProps {
	icon: ReactNode
}

function CustomLink({ children, to, icon, ...props }: ICustomProp) {

	return (
		<Button
			color="primary"
			sx={{ mx: 1, my: 1, display: 'flex' }}
			component={NavLink}
			variant="contained"
			to={to}
			startIcon={icon}
			size="large"
		>
			{children}
		</Button>
	);
}

function Main() {
	const user = useAppSelector((state) => state.auth.user);

	if (user?.unknown && !!user.lotto_allowed) return <Navigate to={`/prodotti/${user.lotto_allowed}`} />;;
	return (
		<Container sx={{ display: "flex", flex: 1, flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
			<Box sx={{ m: 1, userSelect: "none" }}><img src={logo} alt="logo" height={200} /></Box>
			<Box sx={{ m: 1, display: "flex" }}>
				<CustomLink to="prodotti" icon={<Icon className="fa-light fa-list" sx={{ overflow: "visible" }} />}>Prodotti</CustomLink>
				{(user?.admin || user?.user_group?.group_permissions?.prospects !== EProspectsViews.none) && <CustomLink to="prospects" icon={<Icon className="fa-light fa-address-book" sx={{ overflow: "visible" }} />}>Prospects</CustomLink>}
				{(user?.admin || user?.user_group?.group_permissions?.clients !== EClientsViews.none) && <CustomLink to="clienti" icon={<Icon className="fa-light fa-book-user" sx={{ overflow: "visible" }} />}>Clienti</CustomLink>}
			</Box>
		</Container>
	);
}

export default Main;