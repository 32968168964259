import { GridColumns, GridRenderCellParams, GridValueGetterParams } from "@mui/x-data-grid";
import { IDettaglio } from "../../../../../../interfaces/dettaglio/models";
import { IDettaglioTreeNode } from "../interfaces";
import { CellTreeNode, CellVenduto } from "../tableCells";
import { ICreateHistoryGridColumnsProps } from "./interfaces";

const createAllColumns = ({ onOpen, onClose, openList }: ICreateHistoryGridColumnsProps): GridColumns<IDettaglioTreeNode> => (
	[
		{
			field: 'depth',
			headerName: 'Tipo',
			valueGetter: (params: GridValueGetterParams<unknown, IDettaglioTreeNode>) => params?.row,
			renderCell: (params: GridRenderCellParams<IDettaglioTreeNode>) => <CellTreeNode
				id={params.id as number}
				leaf={params.value?.leaf}
				depth={params.value?.depth}
				tipo={params.value?.tipo_lotto.nome}
				onOpen={onOpen}
				onClose={onClose}
				isOpen={openList.includes(params.id as number)}
			/>,
			sortable: false,
			width: 200
		},
		{
			field: 'suffissoCodice',
			headerName: "",
			sortable: false,
			width: 10,
		},
		{
			field: 'magazzino',
			headerName: "Magazzino",
			valueGetter: (params: GridValueGetterParams<unknown, IDettaglioTreeNode>) => (params?.row.leaf) ? params?.row?.magazzino?.nome : "-------",
			sortable: false,
			width: 120
		},
		{
			field: 'zona',
			headerName: 'Zona',
			valueGetter: (params: GridValueGetterParams<unknown, IDettaglioTreeNode>) => (params?.row.leaf) ? params?.row?.zona : "-------",
			sortable: false,
			width: 80
		},
		{
			field: 'fila',
			headerName: 'Fila',
			valueGetter: (params: GridValueGetterParams<unknown, IDettaglioTreeNode>) => (params?.row.leaf) ? params?.row?.fila : "-------",
			sortable: false,
			width: 80
		},
		{
			field: 'bundle',
			headerName: 'Bundle',
			type: 'number',
			sortable: false,
			renderCell: (params: GridRenderCellParams<string>) => <b>{params.formattedValue}</b>,
			width: 60
		},
		{
			field: 'daLastra',
			headerName: 'Da',
			type: "number",
			sortable: false,
			valueGetter: (params: GridValueGetterParams<undefined, IDettaglio>) => (params?.row?.tipo_lotto?.nome === "Lastre") ? params?.row?.lastreDa : "-------",
			width: 60
		},
		{
			field: 'aLastra',
			headerName: 'A',
			type: 'number',
			sortable: false,
			valueGetter: (params: GridValueGetterParams<undefined, IDettaglio>) => (params?.row?.tipo_lotto?.nome === "Lastre") ? params?.row?.lastreA : "-------",
			width: 60
		},
		{
			field: 'pezzi',
			headerName: 'Pezzi',
			type: 'number',
			sortable: false,
			width: 60
		},
		{
			field: 'misX',
			headerName: 'Lun',
			type: 'number',
			sortable: false,
			width: 80
		},
		{
			field: 'misY',
			headerName: 'Alt',
			type: 'number',
			sortable: false,
			width: 80
		},
		{
			field: 'misZ',
			headerName: 'Spes',
			type: 'number',
			sortable: false,
			width: 80
		},
		{
			field: 'quantitaUM',
			headerName: 'Quantità',
			type: 'number',
			sortable: false,
			renderCell: (params: GridRenderCellParams<number>) => {
				const value = parseFloat(String(params.value).replaceAll(',', '.'));
				return Number(value).toLocaleString("it-IT", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
			},
			width: 80
		},
		{
			field: 'um',
			headerName: '',
			valueGetter: (params: GridValueGetterParams<undefined, IDettaglio>) => (params?.value) ? String(params.value) : "",
			sortable: false,
			width: 15
		},
		{
			field: 'costo',
			headerName: 'Costo',
			type: 'number',
			valueGetter: (params: GridValueGetterParams<undefined, IDettaglio>) => {
				const costiDiretti = params?.row?.costiDiretti || 0;
				const costiPadre = params?.row?.costiPadre || 0;
				const quantity = params?.row?.quantitaUM || 1;
				return (costiDiretti + costiPadre) / quantity;
			},
			renderCell: (params: GridRenderCellParams<number>) => {
				const value = parseFloat(String(params.value).replaceAll(',', '.'));
				return Number(value) ? Number(value).toLocaleString("it-IT", { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : ""
			},
			sortable: false,
			width: 120
		},
		{
			field: 'um_costo',
			headerName: '',
			valueGetter: (params: GridValueGetterParams<undefined, IDettaglio>) => (params?.row?.um) ? String(params?.row?.um) : "",
			sortable: false,
			width: 20
		},
		{
			field: 'prezzo',
			headerName: 'Prezzo',
			type: 'number',
			sortable: false,
			valueGetter: (params: GridValueGetterParams<undefined, IDettaglio>) => params?.row?.prezzo,
			renderCell: (params: GridRenderCellParams<number>) => {
				const value = parseFloat(String(params.value).replaceAll(',', '.'));
				return Number(value) ? Number(value).toLocaleString("it-IT", { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : ""
			},
			width: 120
		},
		{
			field: 'um_prezzo',
			headerName: '',
			sortable: false,
			valueGetter: (params: GridValueGetterParams<undefined, IDettaglio>) => (params?.row?.um) ? String(params?.row?.um) : "",
			width: 20
		},
		{
			field: "stato_lavorazione",
			headerName: "Lavorazione",
			valueGetter: (params: GridValueGetterParams<unknown, IDettaglio>) => params?.row?.stato_lavorazione?.nome,
			sortable: false,
			minWidth: 120,
		},
		{
			field: 'venduto',
			headerName: 'Venduto',
			type: 'actions',
			renderCell: (params: GridRenderCellParams<string>) => (params?.row.leaf) ? <CellVenduto value={parseInt(String(params.formattedValue))} /> : "",
			sortable: false
		}
	]
);

export const createHistoryGridColumns = (props: ICreateHistoryGridColumnsProps): GridColumns<IDettaglioTreeNode> => {
	const { canViewCommercials, canViewStoragePlace, canViewCosts } = props;
	let columns = createAllColumns(props);
	if (!canViewCosts) columns = columns.filter((col => col.field !== 'costo' && col.field !== 'um_costo'));
	if (!canViewCommercials) columns = columns.filter((col => col.field !== 'prezzo' && col.field !== 'um_prezzo'));
	if (!canViewStoragePlace) columns = columns.filter((col => col.field !== 'magazzino' && col.field !== 'zona' && col.field !== 'fila'));
	return columns;
}
