import { Box, Container } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import background from "../../assets/130.jpg";
import { useAuthenticate } from "../../effects/auth";
import { useDisponibilitaList } from '../../effects/dettaglio/attributes/disponibilita';
import { useStatoLavorazioneList } from "../../effects/dettaglio/attributes/statoLavorazione";
import { useTipoLottoList } from '../../effects/dettaglio/attributes/tipoLotto';
import { useCategoriaList } from '../../effects/lotto/attributes/categoria';
import { useFondoList } from '../../effects/lotto/attributes/fondo';
import { useMaterialeList } from '../../effects/lotto/attributes/materiale';
import { useQualitaList } from '../../effects/lotto/attributes/qualita';
import { useUsoList } from '../../effects/lotto/attributes/uso';
import { useVenaturaList } from '../../effects/lotto/attributes/venatura';
import { useCavaList } from '../../effects/place/cava';
import { useFilaList } from "../../effects/place/fila";
import { useMagazzinoList } from '../../effects/place/magazzino';
import { useZonaList } from "../../effects/place/zona";
import { useAppSelector } from "../../store/hooks";
import Breadcrumbs from './components/breadcrumbs';
import NavigationBar from "./components/navigationBar";
import NotificationUI from './components/notification';
import WaitingUI from './components/waiting';

function App() {
	const navigate = useNavigate();
	const [query, setQuery] = useState<number>(0);
	useAuthenticate();
	const isLogged = useAppSelector((state) => state.auth.isLogged);
	const user = useAppSelector((state) => state.auth.user);
	const onLogout = () => {
		navigate("/login");
	}
	useEffect(() => {
		if (!!isLogged) setQuery(1);
		if (!isLogged) setQuery(0);
	}, [isLogged])
	useCategoriaList(query);
	useFondoList(query);
	useMaterialeList(query);
	useQualitaList(query);
	useTipoLottoList(query);
	useDisponibilitaList(query);
	useStatoLavorazioneList(query);
	useUsoList(query);
	useVenaturaList(query);
	useCavaList(query);
	useMagazzinoList(query);
	useZonaList(query);
	useFilaList(query);
	return (
		<React.Fragment>
			<Box sx={{ flex: 1, display: "flex", flexDirection: "column", backgroundImage: `url('${background}')`, backgroundSize: "cover" }} >
				{(isLogged && user) &&
					<NavigationBar user={user} onLogout={onLogout} />
				}
				{isLogged &&
					<Breadcrumbs user={user} />
				}
				<Container maxWidth={false} disableGutters={true} sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
					<Outlet />
				</Container>
			</Box>
			<WaitingUI />
			<NotificationUI />
		</React.Fragment>
	);
}

export default App;
