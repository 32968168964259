import { IDisponibilita } from "../../../interfaces/dettaglio/attributes/models";
import { IBaseError } from "../../../interfaces/errors";
import { IListResponse } from "../../../interfaces/rest";
import { getJWT } from "../../../lib/jwt";


const BASE_URL = `${process.env.REACT_APP_API_URL}disponibilita`;

export const getDisponibilita = async (id: number): Promise<IDisponibilita> => {
	const url = `${BASE_URL}/${id}`;
	const headers = new Headers();
	headers.append('Content-Type', 'application/json');
	const jwt = getJWT();
	if (jwt) headers.append('Authorization', 'Bearer ' + jwt);
	const response = await fetch(url, {
		method: "GET",
		headers: headers
	});
	const json: IDisponibilita | IBaseError = await response.json();
	if (response.status !== 200) throw json;
	return json as IDisponibilita;
}

export const getDisponibilitaList = async (): Promise<IListResponse<IDisponibilita>> => {
	const url = `${BASE_URL}/list`;
	const headers = new Headers();
	headers.append('Content-Type', 'application/json');
	const jwt = getJWT();
	if (jwt) headers.append('Authorization', 'Bearer ' + jwt);
	const response = await fetch(url, {
		method: "GET",
		headers: headers
	});
	const json: IListResponse<IDisponibilita> | IBaseError = await response.json();
	if (response.status !== 200) throw json;
	return json as IListResponse<IDisponibilita>;
}