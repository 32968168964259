import { Chip, Divider, Grid, Icon } from '@mui/material';
import Box from "@mui/material/Box";
import { useEffect, useState } from "react";
import { IFilterOption } from '../../../../../interfaces/dettaglio/models';
import { EOptionType } from '../../../../../interfaces/enums';
import { useAppSelector } from "../../../../../store/hooks";
import AltreInformazioniFiltersContainer from './filters/altre';
import CommercialeFiltersContainer from './filters/commerciale';
import MaterialeFiltersContainer from './filters/materiale';
import PosizioniFiltersContainer from './filters/posizioni';
import QualitaFiltersContainer from './filters/qualita';
import { ISidebarProps } from './interfaces';

function Sidebar({ filters, setFilters }: ISidebarProps) {

	const [canViewQuarry, setCanViewQuarry] = useState<boolean>(false);
	const [canViewStoragePlace, setCanViewStoragePlace] = useState<boolean>(false);
	const [canViewCommercialOptions, setCanViewCommercialOptions] = useState<boolean>(false);
	const [showFilters, setShowFilters] = useState<boolean>(false);

	const user = useAppSelector((state) => state.auth.user);

	const removeFilterOption = (option: IFilterOption) => {
		setFilters(filters.filter(opt => !(opt.type === option.type && opt.numericValue === option.numericValue)));
	}

	const removeMagazzinoOptions = (_: IFilterOption) => {
		setFilters(filters.filter(opt => (opt.type !== EOptionType.magazzino && opt.type !== EOptionType.zona && opt.type !== EOptionType.fila)));
	}

	const removeZonaOptions = (_: IFilterOption) => {
		setFilters(filters.filter(opt => (opt.type !== EOptionType.zona && opt.type !== EOptionType.fila)));
	}

	const removeDisponibilitàOptions = (_: IFilterOption) => {
		setFilters(filters.filter(opt => (opt.type !== EOptionType.disponibilita && opt.type !== EOptionType.cliente)));
	}

	useEffect(() => {
		const _temp = [...filters].filter(opt => !(opt.type === EOptionType.codice));
		const _showFilters = !!_temp.length;
		setShowFilters(_showFilters)
	}, [filters]);
	useEffect(() => {
		const _canViewStoragePlace = !!user?.admin || !!user?.user_group?.group_permissions?.canViewStoragePlace;
		const _canViewQuarry = !!user?.admin || !!user?.user_group?.group_permissions?.canViewQuarry;
		const _canViewCommercialOptions = !!user?.admin || !!user?.user_group?.group_permissions?.canViewCommercialOptions;
		setCanViewStoragePlace(_canViewStoragePlace);
		setCanViewQuarry(_canViewQuarry);
		setCanViewCommercialOptions(_canViewCommercialOptions);
	}, [user]);
	return (
		<Box sx={{ flex: 1 }}>
			{showFilters &&
				<Grid sx={{ p: 1 }} container>
					{filters.map((option, index) => (
						<Grid key={index} item xs={12} sx={{ p: 0.5, display: "flex", alignItems: "center", justifyContent: "center" }}>
							{option.type === EOptionType.disponibilita && <Chip icon={<Icon className="fa-light fa-briefcase" sx={{ overflow: "visible" }} />} label={option.stringValue} onDelete={() => removeDisponibilitàOptions(option)} />}
							{option.type === EOptionType.cliente && <Chip icon={<Icon className="fa-light fa-users" sx={{ overflow: "visible" }} />} label={option.stringValue} onDelete={() => removeFilterOption(option)} />}
							{option.type === EOptionType.prezzo && <Chip icon={<Icon className="fa-light fa-euro-sign" sx={{ overflow: "visible" }} />} label={option.stringValue} onDelete={() => removeFilterOption(option)} />}
							{option.type === EOptionType.statoLavorazione && <Chip icon={<Icon className="fa-light fa-screwdriver-wrench" sx={{ overflow: "visible" }} />} label={option.stringValue} onDelete={() => removeFilterOption(option)} />}
							{option.type === EOptionType.categoria && <Chip icon={<Icon className="fa-light fa-circle-star" sx={{ overflow: "visible" }} />} label={option.stringValue} onDelete={() => removeFilterOption(option)} />}
							{option.type === EOptionType.cava && <Chip icon={<Icon className="fa-light fa-location-dot" sx={{ overflow: "visible" }} />} label={option.stringValue} onDelete={() => removeFilterOption(option)} />}
							{option.type === EOptionType.fondo && <Chip icon={<Icon className="fa-light fa-palette" sx={{ overflow: "visible" }} />} label={option.stringValue} onDelete={() => removeFilterOption(option)} />}
							{option.type === EOptionType.magazzino && <Chip icon={<Icon className="fa-light fa-warehouse-full" sx={{ overflow: "visible" }} />} label={option.stringValue} onDelete={() => removeMagazzinoOptions(option)} />}
							{option.type === EOptionType.zona && <Chip icon={<Icon className="fa-light fa-map-pin" sx={{ overflow: "visible" }} />} label={option.stringValue} onDelete={() => removeZonaOptions(option)} />}
							{option.type === EOptionType.fila && <Chip icon={<Icon className="fa-light fa-lines-leaning" sx={{ overflow: "visible" }} />} label={option.stringValue} onDelete={() => removeFilterOption(option)} />}
							{option.type === EOptionType.materiale && <Chip icon={<Icon className="fa-light fa-object-group" sx={{ overflow: "visible" }} />} label={option.stringValue} onDelete={() => removeFilterOption(option)} />}
							{option.type === EOptionType.qualita && <Chip icon={<Icon className="fa-light fa-circle-bookmark" sx={{ overflow: "visible" }} />} label={option.stringValue} onDelete={() => removeFilterOption(option)} />}
							{option.type === EOptionType.tipoLotto && <Chip icon={<Icon className="fa-light fa-shapes" sx={{ overflow: "visible" }} />} label={option.stringValue} onDelete={() => removeFilterOption(option)} />}
							{option.type === EOptionType.uso && <Chip icon={<Icon className="fa-light fa-ruler" sx={{ overflow: "visible" }} />} label={option.stringValue} onDelete={() => removeFilterOption(option)} />}
							{option.type === EOptionType.venatura && <Chip icon={<Icon className="fa-light fa-droplet" sx={{ overflow: "visible" }} />} label={option.stringValue} onDelete={() => removeFilterOption(option)} />}
							{option.type === EOptionType.daSegare && <Chip icon={<Icon className="fa-light fa-info" sx={{ overflow: "visible" }} />} label="Blocchi da segare" onDelete={() => removeFilterOption(option)} />}
						</Grid>
					))}
				</Grid>
			}
			{canViewCommercialOptions &&
				<>
					<Divider />

					<CommercialeFiltersContainer
						filters={filters}
						setFilters={setFilters}
					/>
				</>
			}

			<Divider />

			<MaterialeFiltersContainer
				filters={filters}
				setFilters={setFilters}
				canViewQuarry={canViewQuarry}
			/>

			<Divider />

			<QualitaFiltersContainer
				filters={filters}
				setFilters={setFilters}
			/>

			{canViewStoragePlace &&
				<>
					<Divider />

					<PosizioniFiltersContainer
						filters={filters}
						setFilters={setFilters}
					/>

				</>
			}
			<Divider />

			<AltreInformazioniFiltersContainer
				filters={filters}
				setFilters={setFilters}
			/>
		</Box >
	)
}

export default Sidebar;