import { IMateriale } from "../../../../interfaces/lotto/attributes/models";

export const GET = "lotto_materiale/get";
export const LIST = "lotto_materiale/list";
export const EMPTY = "lotto_materiale/empty";

export interface IMaterialeState {
	results: number;
	list: number[];
	items: Record<number, IMateriale> | null;
};

export const initialState: IMaterialeState = {
	results: 0,
	list: [],
	items: null
};