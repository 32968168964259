import { IBaseError } from "../../../interfaces/errors";
import { IVenatura } from "../../../interfaces/lotto/attributes/models";
import { IListResponse } from "../../../interfaces/rest";
import { getJWT } from "../../../lib/jwt";


const BASE_URL = `${process.env.REACT_APP_API_URL}venatura`;

export const getVenatura = async (id: number): Promise<IVenatura> => {
	const url = `${BASE_URL}/${id}`;
	const headers = new Headers();
	headers.append('Content-Type', 'application/json');
	const jwt = getJWT();
	if (jwt) headers.append('Authorization', 'Bearer ' + jwt);
	const response = await fetch(url, {
		method: "GET",
		headers: headers
	});
	const json: IVenatura | IBaseError = await response.json();
	if (response.status !== 200) throw json;
	return json as IVenatura;
}

export const getVenaturaList = async (): Promise<IListResponse<IVenatura>> => {
	const url = `${BASE_URL}/list`;
	const headers = new Headers();
	headers.append('Content-Type', 'application/json');
	const jwt = getJWT();
	if (jwt) headers.append('Authorization', 'Bearer ' + jwt);
	const response = await fetch(url, {
		method: "GET",
		headers: headers
	});
	const json: IListResponse<IVenatura> | IBaseError = await response.json();
	if (response.status !== 200) throw json;
	return json as IListResponse<IVenatura>;
}
