import { Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { forwardRef, ForwardRefRenderFunction, useEffect, useImperativeHandle, useState } from "react";
import { useDettaglioUpdateStatoLavorazione } from "../../../../../../../effects/dettaglio/dettaglio";
import { IStatoLavorazione } from "../../../../../../../interfaces/dettaglio/attributes/models";
import { DettaglioUpdateStatoLavorazioneFormData } from "../../../../../../../interfaces/dettaglio/forms";
import { IDettaglio } from "../../../../../../../interfaces/dettaglio/models";
import { useAppSelector } from "../../../../../../../store/hooks";
import { IUpdateDialogFormsProps, UpdateDialogFormsHandle } from "../interfaces";

const notToModifiedItem: IStatoLavorazione = {
	id: -2,
	nome: "-- Non modificare --"
}

const emptyItem: IStatoLavorazione = {
	id: -1,
	nome: "-- Nessuno --"
}

const ProcessingStatusForm: ForwardRefRenderFunction<UpdateDialogFormsHandle, IUpdateDialogFormsProps> = (({ lottoId, selectedItems, hidden }, ref) => {
	const [ids, setIds] = useState<number[]>([]);
	const [initValue, setInitValue] = useState<IDettaglio | null>(null);
	const [selectedStatoLavorazione, setSelectedStatoLavorazione] = useState<IStatoLavorazione | null>(null);
	const [statoLavorazioneOptions, setStatoLavorazioneOptions] = useState<IStatoLavorazione[]>([]);

	const [formData, setFormData] = useState<DettaglioUpdateStatoLavorazioneFormData>(null);

	const statoLavorazioneList = useAppSelector((state) => state.dettaglio_stato_lavorazione.list);
	const statoLavorazioneMap = useAppSelector((state) => state.dettaglio_stato_lavorazione.items);

	const onStatoLavorazioneChange = (event: SelectChangeEvent<string>) => {
		const statoLavorazioneId = parseInt(String(event.target.value));
		const statoLavorazione = statoLavorazioneOptions.find(stato => stato.id === statoLavorazioneId) || null;
		setSelectedStatoLavorazione(statoLavorazione);
	};

	const onSubmit = () => {
		const initStatoLavorazione = initValue?.stato_lavorazione || null;
		if (
			!ids.length ||
			(
				!!initValue &&
				initStatoLavorazione?.id === selectedStatoLavorazione?.id
			)
		) {
			return;
		}

		const _formData: DettaglioUpdateStatoLavorazioneFormData = {}
		if (selectedStatoLavorazione?.id === emptyItem.id) _formData.statoLavorazione = null;
		else if (selectedStatoLavorazione?.id === notToModifiedItem.id) _formData.statoLavorazione = undefined;
		else _formData.statoLavorazione = selectedStatoLavorazione;
		setFormData(_formData);
	}

	useImperativeHandle(ref, () => ({
		onSubmit
	}));

	useEffect(() => {
		setFormData(null);
		if (selectedItems.length === 1) {
			setInitValue(selectedItems[0]);
		} else {
			setInitValue(null);
		}
		const _ids = selectedItems.map(item => item.id);
		setIds(_ids);
	}, [selectedItems])

	useEffect(() => {
		if (!!initValue) {
			const statoLavorazioneId = initValue.idStatoLavorazione || null;
			const statoLavorazione = statoLavorazioneOptions.find(stato => stato.id === statoLavorazioneId);
			if (!!statoLavorazione) setSelectedStatoLavorazione(statoLavorazione);
		} else {
			setSelectedStatoLavorazione(notToModifiedItem);
		}
	}, [initValue, statoLavorazioneOptions]);

	useEffect(() => {
		const _statoLavorazioneOptions: IStatoLavorazione[] = (ids.length > 1) ? [notToModifiedItem, emptyItem] : [emptyItem];
		if (!!statoLavorazioneList && !!statoLavorazioneMap) {
			_statoLavorazioneOptions.push(...statoLavorazioneList.map(item => statoLavorazioneMap[item]));
			setStatoLavorazioneOptions(_statoLavorazioneOptions);
		}
	}, [statoLavorazioneList, statoLavorazioneMap, ids]);

	useDettaglioUpdateStatoLavorazione(lottoId, ids, formData);

	return (
		<div
			role="tabpanel"
			hidden={hidden}
			id="update-tabpanel-0"
			aria-labelledby="update-tab-0"
		>
			<Box
				sx={{ display: "flex", gap: 1 }}
			>
				<FormControl fullWidth sx={{ mt: 1, mb: 0.5 }}>
					<InputLabel id="attributi_label">Stato lavorazione</InputLabel>
					<Select
						labelId="attributi_label"
						id="attributi_select"
						value={String(selectedStatoLavorazione?.id)}
						label="Stato lavorazione"
						onChange={onStatoLavorazioneChange}
					>
						{statoLavorazioneOptions.map((item) => (<MenuItem key={String(item.id)} value={String(item.id)}>{item.nome}</MenuItem>))}
					</Select>
				</FormControl>
			</Box>
		</div>
	)
});

export default forwardRef(ProcessingStatusForm);