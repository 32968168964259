import { Box, Dialog, Icon, IconButton, Toolbar } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import { useEffect, useRef, useState } from 'react';
import Carousel from 'react-material-ui-carousel';
import Transition from '../../../../../../../lib/dialogTransition';
import ImageItem from './imageItem';
import { IImageCarouselDialogProps } from './interfaces';

function CarouselDialog({ images, isOpen, startIndex, close, zoom }: IImageCarouselDialogProps) {
	const carousel = useRef<HTMLElement>();
	const [height, setHeight] = useState<number>();
	const getSize = () => {
		const newHeight = carousel?.current?.clientHeight;
		setHeight(newHeight);
	}
	useEffect(() => {
		if (isOpen) window.addEventListener("resize", getSize);
		else window.removeEventListener('resize', getSize);
		setTimeout(getSize, 500);
	}, [isOpen])
	return (
		<Dialog
			fullScreen
			open={isOpen}
			onClose={close}
			TransitionComponent={Transition}
		>
			<AppBar position="absolute" color="transparent" sx={{ boxShadow: "none" }}>
				<Toolbar variant='dense'>
					<Box sx={{ flex: 1 }} />
					<IconButton
						edge="end"
						color="inherit"
						onClick={close}
						aria-label="close"
					>
						<Icon className="fa-light fa-xmark" sx={{ overflow: "visible" }} />
					</IconButton>
				</Toolbar>
			</AppBar>
			<Box sx={{ p: 1, height: "100%", boxSizing: "border-box", display: "flex", flexDirection: "column" }} ref={carousel}>
				<Carousel
					sx={{ flex: 1 }}
					index={startIndex}
					autoPlay={false}
					navButtonsAlwaysVisible={true}
					animation="slide"
					indicatorContainerProps={{
						style: {
							position: "absolute",
							bottom: '10px', // 5
						}
					}}
				>
					{
						images.map((image, i) => <ImageItem key={i} image={image} height={height} zoom={zoom} />)
					}
				</Carousel>
			</Box>
		</Dialog>
	);
}

export default CarouselDialog;